import React, { useMemo, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_EXPERIENCES_COORDINATOR, ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import { formatDateShort } from '~/services/TimeService';

import { buildOfferTypes } from '../orderMetaUtils';

import { CruiseOrderItem } from './constants';

const getOccupancyTypesCount = (
  bookingInfo: CruisesContract.BookingOrderResponse,
): {
  adult: number;
  child: number;
  infant: number;
} => {
  const adult = bookingInfo.travellersInfo.filter((traveller) => traveller.type === 'ADT').length;
  const child = bookingInfo.travellersInfo.filter((traveller) => traveller.type === 'CHD').length;
  const infant = bookingInfo.travellersInfo.filter((traveller) => traveller.type === 'INF').length;

  return { adult, child, infant };
};

const manualCancellationVendors = ['Virgin Voyages', 'Hurtigruten Cruise Line'];

const isManualCancellation = (cruiseLine: string): boolean => {
  return manualCancellationVendors.includes(cruiseLine);
};

const currencyFormatter = (currencyCode = 'AUD', price: number): string => {
  return new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'code',
  }).format(price);
};

type Props = {
  order: App.Order;
  count: number;
  currencyCode: string;
  hasAllowedRefund: boolean;
  cruiseItem: CruiseOrderItem;
  departure: CruisesContract.DepartureByIdResponse;
  bookingInfo: CruisesContract.BookingOrderResponse;
  showRefundModal: ({ itemId }) => void;
};

export default function CruiseOrderItemDetails({
  departure,
  cruiseItem,
  bookingInfo,
  showRefundModal,
  hasAllowedRefund,
  count,
  order,
}: Props) {
  const [open, setOpen] = useState(false);
  const offerTypes = buildOfferTypes(order).join(', ');

  const handleRefund = useMemo(() => {
    if (isManualCancellation(departure.cruiseLine)) {
      return () => setOpen(true);
    }
    return () => showRefundModal({ itemId: cruiseItem.id });
  }, [departure.cruiseLine, cruiseItem.id, showRefundModal]);

  const handleClose = useMemo(() => {
    setOpen(false);
    return () => showRefundModal({ itemId: cruiseItem.id });
  }, [cruiseItem.id, showRefundModal]);

  const rateCodeDescription = bookingInfo.rateCodeDescription
    ? bookingInfo.rateCodeDescription
    : bookingInfo.rateCodeDetails;

  const disabled = useMemo(() => {
    return cruiseItem.status.toUpperCase() === 'CANCELLED';
  }, [cruiseItem.status]);

  const hasConversion =
    bookingInfo.baseCurrency &&
    bookingInfo.convertedCurrency &&
    bookingInfo.baseCurrency !== bookingInfo.convertedCurrency;

  const originalTotalCostPrice = bookingInfo.originalTotalAmount - (bookingInfo.originalCommissionAmount || 0);

  const categoryName = bookingInfo.sailingPricedCategoryName ?? bookingInfo.sailingBerthedCategoryName;
  const categoryCode = bookingInfo.pricedCategoryCode ?? bookingInfo.berthedCategoryCode;

  const luxPlusOnboardCredit = useMemo(() => {
    const onboardCreditInclusion = bookingInfo.inclusions?.find(
      ({ inclusionType, inclusionItemType }) => inclusionType === 'LUX_PLUS' && inclusionItemType === 'ONBOARD_CREDIT',
    );

    if (onboardCreditInclusion) {
      return currencyFormatter(onboardCreditInclusion.currency, onboardCreditInclusion.amount);
    }

    return null;
  }, [bookingInfo.inclusions]);

  const onboardCredit = bookingInfo.onboardCredit
    ? currencyFormatter(bookingInfo.onboardCredit.currency, bookingInfo.onboardCredit.amount)
    : null;

  const buildTravellersSectionHeader = (bookingInfo: CruisesContract.BookingOrderResponse): string => {
    const { adult, child, infant } = getOccupancyTypesCount(bookingInfo);
    let header = `${adult} Adult${adult > 1 ? 's' : ''}`;

    if (child > 0) {
      header += `, ${child} Child${child > 1 ? 'ren' : ''}`;
    }

    if (infant > 0) {
      header += `, ${infant} Infant${infant > 1 ? 's' : ''}`;
    }

    return `(${header})`;
  };

  return (
    <Box p={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="h6">
            {count}. {bookingInfo.deckName} - {categoryCode && categoryName ? `[${categoryCode}] ${categoryName}` : ''}{' '}
            - {bookingInfo.cabinNumber}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" fontWeight="bold">
            Total Sell Price:{' '}
            {hasConversion &&
              bookingInfo.totalAmount &&
              bookingInfo.convertedCurrency &&
              currencyFormatter(bookingInfo.convertedCurrency, bookingInfo.totalAmount)}
            {!hasConversion &&
              bookingInfo.originalTotalAmount &&
              bookingInfo.baseCurrency &&
              currencyFormatter(bookingInfo.baseCurrency, bookingInfo.originalTotalAmount)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" fontWeight="bold">
            Total Cost Price:{' '}
            {bookingInfo.baseCurrency &&
              bookingInfo.originalTotalAmount &&
              currencyFormatter(bookingInfo.baseCurrency, originalTotalCostPrice)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" fontWeight="bold">
            Vendor Status: {cruiseItem.status.toUpperCase()}
          </Typography>
        </Box>
        <PermissionedComponent
          requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_EXPERIENCES_COORDINATOR, ROLE_ICS_STORE_TEAM]}
        >
          <ButtonGroup variant="outlined" size="small">
            {hasAllowedRefund && (
              <Button disabled={disabled} onClick={handleRefund} variant="text">
                CANCEL/REFUND CABIN
              </Button>
            )}
          </ButtonGroup>
        </PermissionedComponent>
      </Stack>

      <Stack mt={4} spacing={4} direction="row">
        <Stack direction="column" spacing={0.5}>
          <Typography variant="body1">
            Offer type: <b>{offerTypes}</b>
          </Typography>
          <Typography variant="body1">
            Vendor Confirmation Number: <b>{bookingInfo.confirmationNumber}</b>
          </Typography>
          <Typography variant="body1">
            Deck: <b>{bookingInfo.deckName}</b>
          </Typography>
          <Typography variant="body1">
            Cabin Number: <b>{bookingInfo.cabinNumber}</b>
          </Typography>
          <Typography variant="body1">
            Cabin Type: <b>{categoryCode && categoryName ? `[${categoryCode}] ${categoryName}` : ''}</b>
          </Typography>
          <Typography variant="body1">
            Rate Code:{' '}
            <Typography component="span" fontWeight="bold">
              {bookingInfo.rateCode}
            </Typography>
          </Typography>
          <Typography variant="body1">
            Rate Code Description: <b>{rateCodeDescription}</b>
          </Typography>
          <Typography variant="body1">
            Onboard Credit for the cabin: <b>{onboardCredit ? onboardCredit : 'N/A'}</b>
          </Typography>
          <Typography variant="body1">
            LP+ Onboard Credit for the cabin: <b>{luxPlusOnboardCredit ? luxPlusOnboardCredit : 'N/A'}</b>
          </Typography>
          <Typography variant="body1">
            Session ID: <b>{bookingInfo.sessionId}</b>
          </Typography>
          <Typography variant="body1">
            Revelex Departure ID:{' '}
            <Typography component="span" fontWeight="bold">
              {departure.externalId}
            </Typography>
          </Typography>
        </Stack>
      </Stack>

      <Divider orientation="horizontal" sx={{ margin: '12px 0' }} />

      <Stack mt={4} direction="column" spacing={3}>
        <Accordion variant="outlined">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Travellers Section {buildTravellersSectionHeader(bookingInfo)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2} direction="row" divider={<Divider orientation="vertical" flexItem />}>
              {bookingInfo.travellersInfo.map((traveller, index) => (
                <Stack direction="column" spacing={0.5} key={index}>
                  <Typography variant="body1">
                    Traveller {index + 1}
                    {index === 0 && ' - Cabin contact'}
                  </Typography>
                  <Typography variant="body1">
                    Type: <b>{traveller.type}</b>
                  </Typography>
                  <Typography variant="body1">
                    Name:{' '}
                    <b>
                      {traveller.firstName} {traveller.middleName} {traveller.lastName}
                    </b>
                  </Typography>
                  <Typography variant="body1">
                    Phone: <b>{traveller.phone}</b>
                  </Typography>
                  <Typography variant="body1">
                    Email: <b>{traveller.email}</b>
                  </Typography>
                  <Typography variant="body1">
                    Date of Birth: <b>{formatDateShort(traveller.dateOfBirth)}</b>
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>

      {bookingInfo.failureReason && (
        <Box mt={4}>
          <hr />
          <Box mt={2}>
            <Typography variant="body1">
              Failure Reason: <b>{bookingInfo.failureReason}</b>
            </Typography>
          </Box>
        </Box>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Attention</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This vendor does not support automated booking cancellation via API. If you proceed to the next step and
            this booking is refunded, the LE cruise operations team AND/OR the vendor will then be notified via email,
            to cancel this booking in the vendor system.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ok</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
