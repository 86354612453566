import { useMemo } from 'react';

import { getSearchInputFromSearchParams } from '~/components/Accommodation/Pages/Properties/components/utils';

export default function usePagedSearch(urlSearchParams: URLSearchParams) {
  return useMemo(() => {
    const pageFromSearchParams = urlSearchParams.get('page');
    return {
      searchInput: getSearchInputFromSearchParams(urlSearchParams),
      pageNumber: pageFromSearchParams ? Number(pageFromSearchParams) : 1,
    };
  }, [urlSearchParams]);
}
