type HistoryItem = {
  created_at: string;
  type: 'login' | 'email_change' | 'order' | 'phone_change';
  object: any;
};

export const combineHistory = (
  logins: Array<any>,
  emailChanges: Array<any>,
  orders: Array<any>,
  phoneChanges: Array<any>,
): Array<HistoryItem> => {
  const loginItems = logins.map((login) => ({
    created_at: login.login_date,
    type: 'login' as const,
    object: login,
  }));

  const emailItems = emailChanges.map((change) => ({
    created_at: change.created_at,
    type: 'email_change' as const,
    object: change,
  }));

  const orderItems = orders.map((order) => ({
    created_at: order.created_at,
    type: 'order' as const,
    object: order,
  }));

  const phoneItems = phoneChanges.map((change) => ({
    created_at: change.created_at,
    type: 'phone_change' as const,
    object: change,
  }));

  return [...loginItems, ...emailItems, ...orderItems, ...phoneItems].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );
};
