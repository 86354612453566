import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';

import { operations } from '@luxuryescapes/contract-svc-promo';
import { getRegionCodes } from '@luxuryescapes/lib-regions';

import useCurrentTenant from '~/hooks/useCurrentTenant';

import { createPromoDisplayConfig, updatePromoDisplayConfig } from '~/services/PromoService';

type AuthState = 'logged_in' | 'logged_out' | 'logged_in_and_out' | null;
type DisplayMode = 'appear_once' | 'persistence' | null;

const isValidQueryParamsRegex = /^[^\s]+$/;

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  fetchData: () => void;
  editableConfig?: operations['updatePromoDisplayConfig']['parameters']['body']['payload'];
}

function PromoDisplayConfigForm({ open, setOpen, fetchData, editableConfig }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [region, setRegion] = useState<string | null>(editableConfig?.region);
  const [displayMode, setDisplayMode] = useState<DisplayMode>(editableConfig?.display_mode ?? 'appear_once');
  const [authState, setAuthState] = useState<AuthState>(editableConfig?.auth_state ?? 'logged_in_and_out');
  const isEditing = !!editableConfig;
  const tenant = useCurrentTenant();
  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleCreate = useCallback(
    async (data) => {
      const response = await createPromoDisplayConfig({
        ...data,
        brand: tenant.tenant.brand,
        region,
        display_mode: displayMode,
        auth_state: authState,
        delay_duration: Number(data.delay_duration),
      });

      if (response.status !== 201) {
        enqueueSnackbar('Promo Display Config creation failed', { variant: 'error' });
        return;
      }

      reset();
      fetchData();
      handleClose();
      enqueueSnackbar('Promo Display Config created successfully', { variant: 'success' });
    },
    [enqueueSnackbar, fetchData, reset, tenant.tenant.brand, region, displayMode, authState],
  );

  const handleEdit = useCallback(
    async (data) => {
      const response = await updatePromoDisplayConfig({
        ...data,
        brand: tenant.tenant.brand,
        promo_display_config_id: editableConfig.promo_display_config_id,
        region,
        display_mode: displayMode,
        auth_state: authState,
        delay_duration: Number(data.delay_duration),
      });

      if (response.status !== 200) {
        enqueueSnackbar('Promo Display Config edit failed', { variant: 'error' });
        return;
      }

      reset();
      fetchData();
      handleClose();
      enqueueSnackbar('Promo Display Config edited successfully', { variant: 'success' });
    },
    [enqueueSnackbar, fetchData, reset, tenant.tenant.brand, region, displayMode, authState],
  );

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={true}
        onClose={handleClose}
        component="form"
        onSubmit={handleSubmit(isEditing ? handleEdit : handleCreate)}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {isEditing ? 'Edit' : 'Create'} a Promo Display Config
        </DialogTitle>
        <DialogContent dividers>
          <Stack spacing={2}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Stack spacing={2}>
                  <TextField
                    id="code_name"
                    label="code_name"
                    variant="outlined"
                    {...register('code_name', {
                      required: 'Promo Code Name is required',
                    })}
                    defaultValue={editableConfig?.code_name}
                    error={!!errors.code_name}
                    helperText={errors.code_name?.message}
                    autoComplete="off"
                  />
                  <TextField
                    id="url_path"
                    label="URL path"
                    variant="outlined"
                    {...register('url_path', {
                      required: 'URL path is required',
                    })}
                    defaultValue={editableConfig?.url_path}
                    error={!!errors.url_path}
                    helperText={errors.url_path?.message}
                    autoComplete="off"
                  />
                  <Autocomplete
                    id="region"
                    options={getRegionCodes()}
                    value={region}
                    onChange={(_, newValue) => {
                      setRegion(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Region" />}
                  />
                  <Autocomplete
                    id="display_mode"
                    options={['appear_once', 'persistence']}
                    value={displayMode}
                    onChange={(_, newValue) => {
                      setDisplayMode(newValue as 'appear_once' | 'persistence' | null);
                    }}
                    renderInput={(params) => <TextField {...params} label="Display Mode" />}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={2}>
                  <TextField
                    id="source"
                    label="Source"
                    variant="outlined"
                    {...register('source', {
                      pattern: {
                        value: isValidQueryParamsRegex,
                        message: 'utm_source',
                      },
                    })}
                    defaultValue={editableConfig?.source}
                    error={!!errors.source}
                    helperText={errors.source?.message}
                    autoComplete="off"
                  />
                  <TextField
                    id="medium"
                    label="Medium"
                    variant="outlined"
                    {...register('medium', {
                      pattern: {
                        value: isValidQueryParamsRegex,
                        message: 'utm_medium',
                      },
                    })}
                    defaultValue={editableConfig?.medium}
                    error={!!errors.medium}
                    helperText={errors.medium?.message}
                    autoComplete="off"
                  />
                  <TextField
                    id="campaign"
                    label="Campaign"
                    variant="outlined"
                    {...register('campaign', {
                      pattern: {
                        value: isValidQueryParamsRegex,
                        message: 'utm_campaign',
                      },
                    })}
                    defaultValue={editableConfig?.campaign}
                    error={!!errors.campaign}
                    helperText={errors.campaign?.message}
                    autoComplete="off"
                  />
                  <Autocomplete
                    id="auth_state"
                    options={['logged_in', 'logged_out', 'logged_in_and_out']}
                    value={authState}
                    onChange={(_, newValue) => {
                      setAuthState(newValue as AuthState);
                    }}
                    renderInput={(params) => <TextField {...params} label="Auth State" />}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <Tooltip title="Unit of delay duration is in seconds" placement="bottom">
                    <TextField
                      id="delay_duration"
                      label="Delay Duration"
                      variant="outlined"
                      {...register('delay_duration', {
                        required: 'Delay Duration is required',
                      })}
                      inputProps={{ min: 0 }}
                      type="number"
                      defaultValue={editableConfig?.delay_duration}
                      error={!!errors.delay_duration}
                      helperText={errors.delay_duration?.message}
                      autoComplete="off"
                    />
                  </Tooltip>
                  <TextField
                    id="header_text"
                    label="Header Text"
                    variant="outlined"
                    {...register('header_text', {
                      required: 'Header text is required',
                    })}
                    inputProps={{ maxLength: 40 }}
                    defaultValue={editableConfig?.header_text}
                    error={!!errors.header_text}
                    helperText={errors.header_text?.message}
                    autoComplete="off"
                  />
                  <TextField
                    id="body_text"
                    label="Body Text"
                    variant="outlined"
                    {...register('body_text', {
                      required: 'Body text is required',
                    })}
                    inputProps={{ maxLength: 80 }}
                    defaultValue={editableConfig?.body_text}
                    error={!!errors.body_text}
                    helperText={errors.body_text?.message}
                    multiline
                    rows={4}
                    autoComplete="off"
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" type="submit">
            {isEditing ? 'Edit' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PromoDisplayConfigForm;
