import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import { NavLink } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useProgressPolling } from '~/components/Accommodation/hooks/useProgressPolling';
import Spinner from '~/components/Common/Spinner';

import { InternalService, ProgressStatusResponse, ValidationErrorCodes } from '~/services/AccommodationService';
import { HttpResponse } from '~/services/types';

import ProgressStateItem from './ProgressStateItem';

type Props = {
  open: boolean;
  apiCall: (
    propertyId: string,
    internalService: InternalService,
    validationOverrides?: Array<ValidationErrorCodes>,
  ) => Promise<HttpResponse<ProgressStatusResponse>>;
  onClose: () => void;
};

interface InternalServiceOption {
  value: InternalService;
  label: string;
  description: ReactNode;
}

const INTERNAL_SUPPLIERS: Array<InternalServiceOption> = [
  {
    value: 'reservation',
    label: 'LE',
    description: (
      <>
        Enter the LE property ID to import
        <br />
        Clicking 'Import' will kick off a background import job
        <br />
        This will import any associated bedbank properties
        <br />
        Running progress will be displayed below
      </>
    ),
  },
  {
    value: 'bedbank',
    label: 'Bedbank',
    description: (
      <>
        Enter the Bedbank property ID to import
        <br />
        Clicking 'Import' will kick off a background import job
        <br />
        Running progress will be displayed below
      </>
    ),
  },
];

function AccommodationAddPropertyProgressModal({ open, apiCall, onClose }: Props) {
  const [selectedInternalServiceType, setSelectedInternalServiceType] = useState<InternalService | null>(null);
  const [propertyId, setPropertyId] = useState('');

  const wrappedApiCall = useCallback(
    ({ validationOverrides }: { validationOverrides?: Array<ValidationErrorCodes> }) => {
      return apiCall(propertyId, selectedInternalServiceType, validationOverrides);
    },
    [apiCall, propertyId, selectedInternalServiceType],
  );

  const {
    isLoading,
    isComplete,
    statusMessage,
    errorMessage,
    propertyImportSvcRes,
    propertyImportSvcBedbank,
    generatedPropertyId,
    createBaseProperty,
    validationErrors,
    runStartJob,
    resetAndClose,
  } = useProgressPolling({ apiCall: wrappedApiCall, onClose });

  const handleInputChange = useCallback((e) => {
    setPropertyId(e.target.value);
  }, []);

  const handleSelectInternalServiceType = useCallback((event) => {
    setSelectedInternalServiceType(event.target.value);
  }, []);

  const selectedInternalService = useMemo(() => {
    return INTERNAL_SUPPLIERS.find((option) => option.value === selectedInternalServiceType);
  }, [selectedInternalServiceType]);

  if (validationErrors.length) {
    return (
      <Dialog open={open} onClose={resetAndClose}>
        <DialogTitle>Import the property?</DialogTitle>
        <DialogContent>
          <Stack spacing={4}>
            <Stack spacing={2}>
              {validationErrors.map(({ code, message }) => (
                <DialogContentText key={code}>{message}</DialogContentText>
              ))}
            </Stack>
            <DialogContentText>
              Are you sure you want to{' '}
              <Typography component="span" color="error">
                reimport
              </Typography>{' '}
              this property?
            </DialogContentText>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={resetAndClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => runStartJob(validationErrors.map((e) => e.code))} color="error">
            Yes, reimport property
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={resetAndClose}>
      <DialogTitle>Import Property</DialogTitle>
      <DialogContent sx={{ minWidth: '400px' }}>
        <Stack spacing={2}>
          <DialogContentText>Please Select an Internal Service</DialogContentText>
          <FormControl fullWidth>
            <InputLabel>Internal Service</InputLabel>
            <Select
              value={selectedInternalServiceType}
              label="Internal Supplier"
              onChange={handleSelectInternalServiceType}
            >
              {INTERNAL_SUPPLIERS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!!selectedInternalService && (
            <>
              <DialogContentText>{selectedInternalService.description}</DialogContentText>
              <TextField
                label="Property ID"
                value={propertyId}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </>
          )}

          {propertyImportSvcRes && (
            <ProgressStateItem label="Importing LE property" state={propertyImportSvcRes.state} />
          )}
          {propertyImportSvcBedbank && (
            <ProgressStateItem label="Importing Bedbank property" state={propertyImportSvcBedbank.state} />
          )}
          {createBaseProperty && <ProgressStateItem label="Creating mapping" state={createBaseProperty.state} />}
          {isComplete && statusMessage && <DialogContentText color="primary">{statusMessage}</DialogContentText>}
          {errorMessage && <DialogContentText color="error">{errorMessage}</DialogContentText>}
        </Stack>
      </DialogContent>
      {!isComplete && (
        <DialogActions>
          <Button variant="text" onClick={resetAndClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => runStartJob()} disabled={isLoading || !propertyId}>
            {isLoading ? <Spinner size={15} inline /> : 'Import'}
          </Button>
        </DialogActions>
      )}
      {isComplete && (
        <DialogActions>
          <Stack direction="row" spacing={2} alignItems="center">
            {generatedPropertyId && (
              <Link component={NavLink} to={`/accommodation/properties/${generatedPropertyId}`} target="_blank">
                Open
              </Link>
            )}
            <Button variant="contained" onClick={resetAndClose} disabled={isLoading}>
              Close
            </Button>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default AccommodationAddPropertyProgressModal;
