import React from 'react';

import capitalize from 'lodash/capitalize';
import { useSnackbar } from 'notistack';
import Form from 'react-jsonschema-form';

import { Button, Dialog, DialogContent } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';

import { buttonMessages, buttonStates } from '~/components/Common/Forms/states/submitButton';
import Spinner from '~/components/Common/Spinner';
import DownloaderPopup from '~/components/Downloader/Popup';

import { BEDBANK_PROFILES_ENUM, BEDBANK_PROPERTY_DOMAINS_ENUM, BEDBANK_PROPERTY_STATUSES_ENUM } from '~/consts/bedbank';

import bedbankService from '~/services/BedbankService';

import { addQuery, parseSearchString } from '~/utils/url';

import { reportError } from '../../../../utils/reportError';

import BedbankPropertiesTable from './BedbankPropertiesTable';
import BedbankPropertiesSearchForm from './SearchForm';

interface Props {
  history: any;
  location: any;
  propertyType: string;
  onOpenModalImage: (id: string) => void;
}

interface Query {
  query: string;
  status: Array<string>;
  country: Array<{ label: string; value: string }>;
  chainId: Array<{ label: string; value: string }>;
  ratingOp: string;
  ratingVal: string;
  domain: string;
  comment: string;
  promotions: boolean;
  city: string;
  stateProvince: string;
  profile: string;
  page: number;
  sizePerPage: number;
}

interface Property {
  id: string;
  externalId: string;
  name: string;
  profile: string;
  status: string;
  domain: string;
  comment: string;
  rating: number;
  images?: Array<{ id: string; hidden?: boolean }>;
  updatedAt: Date;
}

interface State {
  isLoading: boolean;
  modalBulkUpdate: boolean;
  bulkUpdateState: string;
  errorMessage: string | null;
  count: number;
  data: Array<Property>;
  chains: Array<{ label: string; value: string }>;
  selected: GridRowSelectionModel;
  exportParts: Array<string>;
}

const DEFAULT_SIZE_PER_PAGE = 15;

const bulkUpdateSchema = (count: number) => ({
  title: `Update selected - ${count}`,
  type: 'object',
  properties: {
    status: {
      type: 'string',
      enum: BEDBANK_PROPERTY_STATUSES_ENUM,
      enumNames: BEDBANK_PROPERTY_STATUSES_ENUM.map((x) => capitalize(x)),
      title: 'Status',
    },
    domain: {
      type: 'string',
      enum: BEDBANK_PROPERTY_DOMAINS_ENUM,
      enumNames: BEDBANK_PROPERTY_DOMAINS_ENUM.map((x) => capitalize(x)),
      title: 'Domain',
    },
    profile: {
      type: 'string',
      enum: BEDBANK_PROFILES_ENUM,
      enumNames: BEDBANK_PROFILES_ENUM.map((x) => capitalize(x)),
      title: 'Profile',
    },
  },
});

const removeEmptyFields = (obj) => Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);

function PageContainerNotify(props: any) {
  const { enqueueSnackbar } = useSnackbar();

  enqueueSnackbar(props.message);

  return null;
}

class PageContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      modalBulkUpdate: false,
      errorMessage: null,
      bulkUpdateState: buttonStates.default,
      data: [],
      count: 0,
      chains: [],
      selected: [],
      exportParts: [],
    };
  }

  componentDidMount() {
    const page = this.currentPage();
    const sizePerPage = this.currentSizePerPage();
    bedbankService.getChains().then((data) => {
      this.setState(
        {
          chains: data.result.map((x) => ({
            label: x.name,
            value: x.id,
          })),
        },
        () => {
          this.fetchData(page, sizePerPage, this.queries());
        },
      );
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.propertyType !== this.props.propertyType) {
      const page = this.currentPage();
      const sizePerPage = this.currentSizePerPage();

      this.fetchData(page, sizePerPage, this.queries());
    }
  }

  onPageChange = (page: number, sizePerPage: number) => {
    const { history, location } = this.props;
    const query = this.queries();
    const newLocation = addQuery(location, {
      ...query,
      page,
      sizePerPage,
    });
    history.push(newLocation);

    this.fetchData(page, sizePerPage, query);
  };

  formatQueryData = (query: Query) => {
    return {
      ...query,
      chainId: (Array.isArray(query.chainId) ? query.chainId : []).map((chain) => chain.value),
      country: (Array.isArray(query.country) ? query.country : []).map((country) => country.value),
      type: this.getType(query.query),
      propertyType: this.props.propertyType,
    };
  };

  getType = (q) => {
    if (Number(q).toString() === q) {
      return 'externalId';
    } else if (bedbankService.isBedbankId(q)) {
      return 'id';
    }
    return 'name';
  };

  onQueryChange = (query: Query) => {
    const { history, location } = this.props;
    const sizePerPage = this.currentSizePerPage();

    const newLocation = addQuery(location, {
      page: 1,
      sizePerPage,
      ...query,
    });
    history.push(newLocation);

    this.fetchData(1, sizePerPage, query);
  };

  fetchData(page, sizePerPage, query) {
    this.setState({ isLoading: true });
    const formattedQuery = this.formatQueryData(query);
    bedbankService.getProperties(page, sizePerPage, formattedQuery).then((data) => {
      this.setState({
        data: data.result,
        count: data.count,
        isLoading: false,
      });
    });
  }

  updateImageData = (images, rowId) => {
    const currentData = this.state.data;
    // update images with hidden status
    const pIndex = currentData.findIndex((p) => p.id === rowId);
    for (const img of images) {
      const iIndex = currentData[pIndex].images.findIndex((image) => image.id === img.id);
      if (iIndex !== -1) {
        currentData[pIndex].images[iIndex].hidden = img.hidden;
      }
    }
    this.setState({ data: currentData });
  };

  currentPage() {
    const page = this.queries().page as string;
    return parseInt(page) || 1;
  }

  currentSizePerPage() {
    const sizePerPage = this.queries().sizePerPage as string;
    return parseInt(sizePerPage) || DEFAULT_SIZE_PER_PAGE;
  }

  getQueryString() {
    const searchQueryString = this.queries().query as string;
    return searchQueryString || '';
  }

  getQueryCountry() {
    const country = this.queries().country as Array<string>;
    return country || [];
  }

  getQueryChains() {
    const chains = this.queries().chainId as Array<string>;
    return chains || [];
  }

  getQueryStatus() {
    const status = this.queries().status as Array<string>;
    return status || [];
  }

  getRatingOperation() {
    const ratingOp = this.queries().ratingOp as string;
    return ratingOp;
  }

  getRatingValue() {
    const ratingOp = this.queries().ratingVal as string;
    return ratingOp;
  }
  getCity() {
    const city = this.queries().city as string;
    return city;
  }

  getStateProvince() {
    const stateProvince = this.queries().stateProvince as string;
    return stateProvince;
  }

  getQueryDomain() {
    const domain = this.queries().domain as string;
    return domain;
  }

  getQueryComment() {
    const comment = this.queries().comment as string;
    return comment;
  }

  getPromotions() {
    const promotions = this.queries().promotions as string;
    return promotions ? (promotions === 'false' ? false : true) : null;
  }

  getQueryProfile() {
    const profile = this.queries().profile as string;
    return profile;
  }

  queries() {
    const { location } = this.props;
    return parseSearchString(location.search);
  }

  handleSearchSubmit = (query: Query) => {
    this.onQueryChange(query);
  };

  handleExportSubmit = (query: Query) => {
    const chunk = 2000;
    const parts = Math.ceil(this.state.count / chunk);
    const exportParts = [];

    for (let part = 0; part < parts; part++) {
      const uri = bedbankService.getPropertiesUrlQuery({
        ...query,
        startOffset: chunk * part,
        chunk: chunk,
        chainId: query.chainId?.map((chain) => chain.value),
        country: query.country?.map((country) => country.value),
        type: this.getType(query.query),
        propertyType: this.props.propertyType,
      });

      const exportPath = bedbankService.basePath() + `/properties/export?${uri}`;
      exportParts.push(exportPath);
    }

    this.setState({ exportParts });
  };

  // See https://mui.com/x/react-data-grid/editing/#the-processrowupdate-callback
  handleStatusUpdate = (newRow: Property) => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const index = this.state.data.findIndex((i) => i.id === newRow.id);
    for (const [key, value] of Object.entries(newRow)) {
      if (this.state.data[index][key] !== value) {
        const newValue = ['true', 'false'].includes(value) ? JSON.parse(value) : value;

        const newState = [...this.state.data];
        newState[index][key] = value; // assuming that we intentionally use value here, not newValue
        bedbankService
          .patchPropertyEdit(newRow.id, { [key]: newValue, updatedAt: this.state.data[index].updatedAt })
          .then(({ result }) => {
            newState[index].updatedAt = result.updatedAt;
            this.setState({ data: newState, errorMessage: null });
          })
          .catch(function (e) {
            if (e.status === 409) {
              self.setState({
                errorMessage:
                  'The property has already been updated. Needs to refresh the page and try to save it again.',
              });
            }

            reportError(e);
          });
      }
    }

    return newRow;
  };

  handleUpdateSubmit = (form) => {
    const payload = {
      ids: this.state.selected,
      fields: {
        ...form.formData,
      },
    };

    removeEmptyFields(payload.fields);

    this.setState(
      {
        bulkUpdateState: buttonStates.saving,
      },
      () => {
        bedbankService
          .bulkPatchPropertiesEdit(payload)
          .then(({ result }) => {
            const data = [...this.state.data];
            for (const updatedData of result) {
              const index = data.findIndex((x) => x.id === updatedData.id);
              if (data[index]) {
                data[index].profile = updatedData.type;
                data[index].status = updatedData.status;
                data[index].domain = updatedData.domain;
              }
            }
            this.setState(
              {
                data,
                bulkUpdateState: buttonStates.saved,
                selected: [],
              },
              () => {
                setTimeout(
                  () =>
                    this.setState({
                      bulkUpdateState: buttonStates.default,
                      modalBulkUpdate: false,
                    }),
                  1000,
                );
              },
            );
          })
          .catch(() => {
            this.setState({
              bulkUpdateState: buttonStates.failed,
            });
          });
      },
    );
  };

  onCloseModalBulkUpdate = () => {
    this.setState({
      modalBulkUpdate: false,
    });
  };

  onCloseDownload = () => {
    this.setState({
      exportParts: [],
    });
  };

  onOpenModalBulkUpdate = () => {
    this.setState({
      modalBulkUpdate: true,
    });
  };

  handleSelect = (ids: GridRowSelectionModel) => {
    this.setState({ selected: ids });
  };

  render() {
    return (
      <div>
        {this.state.errorMessage && <PageContainerNotify message={this.state.errorMessage} />}
        <BedbankPropertiesSearchForm
          handleSubmit={this.handleSearchSubmit}
          handleExportSubmit={this.handleExportSubmit}
          query={this.getQueryString()}
          country={this.getQueryCountry()}
          status={this.getQueryStatus()}
          ratingOp={this.getRatingOperation()}
          ratingVal={this.getRatingValue()}
          city={this.getCity()}
          stateProvince={this.getStateProvince()}
          domain={this.getQueryDomain()}
          comment={this.getQueryComment()}
          promotions={this.getPromotions()}
          profile={this.getQueryProfile()}
          chainId={this.getQueryChains()}
          chains={this.state.chains}
        />
        {this.state.isLoading && <Spinner />}
        {!this.state.isLoading && (
          <BedbankPropertiesTable
            data={this.state.data}
            count={this.state.count}
            selected={this.state.selected}
            onOpenModalImage={this.props.onOpenModalImage}
            onPageChange={this.onPageChange}
            page={this.currentPage()}
            sizePerPage={this.currentSizePerPage()}
            onStatusUpdate={this.handleStatusUpdate}
            updateImageData={this.updateImageData}
            handleSelect={this.handleSelect}
            onOpenModalBulkUpdate={this.onOpenModalBulkUpdate}
          />
        )}
        <DownloaderPopup
          open={this.state.exportParts.length > 0}
          onClose={this.onCloseDownload}
          downloadParts={this.state.exportParts}
        />
        <Dialog open={!!this.state.modalBulkUpdate} onClose={this.onCloseModalBulkUpdate}>
          <DialogContent sx={{ padding: 4 }}>
            <Form schema={bulkUpdateSchema(this.state.selected.length)} onSubmit={this.handleUpdateSubmit}>
              <div className="button-container">
                <Button variant="contained" type="submit" className={this.state.bulkUpdateState}>
                  {buttonMessages[this.state.bulkUpdateState]}
                </Button>
              </div>
            </Form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default PageContainer;
