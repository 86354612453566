import React, { forwardRef } from 'react';

import { Stack } from '@mui/material';

import { AccommodationTabs } from '~/components/Accommodation/Pages/Home/AccommodationHomePage';
import AccommodationPropertiesResultsContainer from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesResultsContainer';
import { AccommodationPropertiesResultsTableRef } from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesResultsTable';
import AccommodationUnmappedSuppliersResultsContainer from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesUnmappedSuppliersResultsContainer';
import PotentialMappingsResultsContainer from '~/components/Accommodation/Pages/Properties/components/PotentialMappingsResultsContainer';

interface Props {
  selectedTab: AccommodationTabs;
  refreshProperties: () => void;
}

const AccommodationProperties = forwardRef<AccommodationPropertiesResultsTableRef, Props>(
  function AccommodationProperties({ selectedTab, refreshProperties }, ref) {
    return (
      <Stack direction="column" spacing={2}>
        {selectedTab === AccommodationTabs.PROPERTIES && (
          <AccommodationPropertiesResultsContainer
            ref={ref}
            selectedTab={selectedTab}
            refreshProperties={refreshProperties}
          />
        )}
        {selectedTab === AccommodationTabs.UNMAPPED_SUPPLIERS && (
          <AccommodationUnmappedSuppliersResultsContainer
            selectedTab={selectedTab}
            refreshProperties={refreshProperties}
          />
        )}
        {selectedTab === AccommodationTabs.POTENTIAL_MAPPINGS && <PotentialMappingsResultsContainer />}
      </Stack>
    );
  },
);

export default AccommodationProperties;
