interface ItineraryCalculation {
  isKnownItinerary: boolean;
  calculatedCombinations: number;
}

export enum Suppliers {
  S1 = 'expedia',
  S2 = 'hotelbeds',
  S3 = 'webbeds',
  S4 = 'stuba',
  S5 = 'dida',
  S6 = 'nuitee',
}

export const calculateItineraryDetails = (
  propertyIds: string,
  checkIn: string | undefined,
  nights: number,
  combinations: number,
): ItineraryCalculation => {
  const numberOfProperties = propertyIds.length > 0 ? propertyIds.split(',').length : 0;
  const isKnownItinerary = numberOfProperties > 1 || (checkIn !== '' && nights > 0 && numberOfProperties === 1);
  return {
    isKnownItinerary,
    calculatedCombinations: isKnownItinerary ? numberOfProperties : combinations,
  };
};
