import React from 'react';

import type { RJSFSchema } from '@rjsf/utils';
import memoize from 'lodash/memoize';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link, RouteChildrenProps, withRouter } from 'react-router-dom';

import { Alert, Box, Button, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import { blue, teal } from '@mui/material/colors';

import { Order } from '@luxuryescapes/contract-svc-order';

import PageHeader from '~/components/Common/Elements/PageHeader';
import UpdateUser from '~/components/Common/UpdateUser';
import {
  checkCanRedeemLuxPlusBenefits,
  checkCanViewLuxPlusBenefits,
  hasLuxPlusSubscriptionStatus,
  isFreePreviewLuxPlusMember,
} from '~/components/Membership/utils';
import UserAbandonedCartsTable from '~/components/System/UserAbandonedCartsTable';
import CreatePromoForUserModal from '~/components/Users/CreatePromoForUserModal';
import UpgradeToLuxPlusButton from '~/components/Users/UserDetails/UpgradeToLuxPlusButton';

import { LUX_PLUS } from '~/consts/membership';
import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_EXPERIENCES_COORDINATOR, ROLE_ICS_STORE_TEAM } from '~/consts/roles';
import TENANTS from '~/consts/tenant';

import { formatDateOrdinalWithClock } from '~/services/TimeService';

import canShowTabExceptRoles from '~/utils/canShowTabExceptRoles';
import { isLuxLoyaltyEnabled } from '~/utils/luxLoyalty';
import { titleCase } from '~/utils/stringUtils';

import PermissionedComponent from '../Common/PermissionedComponent';
import UserAgencyDetails from '../LeAgentHub/components/UserAgencyDetails';
import LuxLoyaltyAccountChip from '../LuxLoyalty/LuxLoyaltyAccountChip';
import LuxLoyaltyAccountDetails from '../LuxLoyalty/LuxLoyaltyAccountDetails';
import MembershipSubscriptionDetails from '../Membership/MembershipSubscriptionDetails';
import SpeedNavigationKeyboardKey from '../System/SpeedNavigationKeyboardKey';
import UserEmailChangeTable from '../System/UserEmailChangeTable';
import UserLoginTable from '../System/UserLoginTable';
import UserPhoneNumberChangeTable from '../System/UserPhoneNumberChangeTable';
import UserRecentOfferPageViewsTable from '../System/UserRecentOfferPageViewsTable';
import UserRecentSearchTable from '../System/UserRecentSearchTable';
import { withTenant } from '../hoc';

import CustomOffersTab from './CustomOffers/CustomOffersTab';
import ForceLogoutModal from './ForceLogoutModal';
import GDPRRemovalModal from './GRPRRemovalModal';
import GDPRRequestModal from './GRPRRequestModal';
import QuotesTab from './Quotes/QuotesTab';
import BusinessAdminSpoofButton from './UserDetails/BusinessAdminSpoofButton';
import PartnershipButtons from './UserDetails/PartnershipButtons';
import ResolveAccountTakeoverInstructions from './UserDetails/ResolveAccountTakeoverInstructions';
import SpoofUserBlock from './UserDetails/SpoofUserBlock';
import TenancyWarningMessage from './UserDetails/TenancyWarningMessage';
import { validUserTenantForSiteTenant } from './UserDetails/utils';
import { EmployeeUser } from './UserPage';

const getRegionsByCountryCode = memoize((regions) => {
  return regions.reduce((regionsByCountryCode, region) => {
    regionsByCountryCode[region.code] = region;
    return regionsByCountryCode;
  }, {});
});

type TabKey =
  | 'user-details'
  | 'quotes'
  | 'custom-offers'
  | 'luxplus-member-profile'
  | 'user-agency'
  | 'recent-searches'
  | 'recent-offer-page-views'
  | 'luxloyalty-member-profile'
  | 'abandoned-carts';

export type UserTenancy = { id: string; tenant: string }; // id is the id of the user

type Props = RouteChildrenProps & {
  user: App.User;
  isAdmin: boolean;
  employeeUserList: Array<EmployeeUser>;
  tenant: App.Tenant;
  tenancy: Array<UserTenancy>;
  schema: { put: RJSFSchema };
  regions: Array<App.Region>;
  updatePreferredAgent: (agent: string) => void;
  update: (user: App.User) => void;
  subscriptions: App.MembershipSubscriptions | null;
  luxLoyaltyAccount: App.LuxLoyaltyAccount | null;
  luxLoyaltyProgramConfig: App.LuxLoyaltyProgramConfig | null;
  currentSubscriptionOrderItem: Order.SubscriptionItem;
  currentSubscriptionOrder: App.Order;
  refreshData: () => Promise<void>;
};

type State = {
  offer_id: string;
  spoof_url: string;
  for_store: boolean;
  isCreatePromoCodeModalVisible: boolean;
  isGDPRRemovalModalVisible: boolean;
  isGDPRRequestModalVisible: boolean;
  isForceLogoutModalVisible: boolean;
  currentTab: TabKey;
  tenant: App.Tenant;
};

const newCustomOfferUri = (user: App.User) => {
  return `/users/${user.id_member}/custom-offers/create`;
};

const historyUri = (user: App.User) => {
  return `/users/${user.id_member}/history`;
};

class UserDetails extends React.Component<Props, State> {
  static contextTypes: { user: PropTypes.Requireable<unknown> };
  UpdateUserForm: { submitButton: HTMLButtonElement };

  constructor(props) {
    super(props);

    const urlSearchParams = new URLSearchParams(props.location.search);
    const showLuxPlusTabInitially =
      urlSearchParams.get('view_luxplus_membership') === 'true' && checkCanViewLuxPlusBenefits(props.subscriptions);

    this.state = {
      offer_id: '',
      spoof_url: '',
      for_store: false,
      isCreatePromoCodeModalVisible: false,
      isGDPRRemovalModalVisible: false,
      isGDPRRequestModalVisible: false,
      isForceLogoutModalVisible: false,
      currentTab: showLuxPlusTabInitially ? 'luxplus-member-profile' : 'user-details',
      tenant: props.tenant,
    };
  }

  setCurrentTab(tab: TabKey) {
    if (this.state.currentTab === 'luxplus-member-profile') {
      this.clearSearchParams();
    }
    this.setState({ currentTab: tab });
  }

  buyPageUri(offer_id: string) {
    return '/offers/' + offer_id + '/buy_for/' + this.props.user.id_member;
  }

  purchasePageUri() {
    return `/purchases/section/allOrders?customer_id=${this.props.user.id_member}`;
  }

  creditsPageUri(userCountry) {
    if (!userCountry) {
      return '#';
    }

    return `/users/${this.props.user.id_member}/credits?currency=${userCountry.currencyCode}`;
  }

  promoRequestPageUri() {
    return `/marketing/promo-requests?userId=${this.props.user.id_member}&page=0&limit=100`;
  }

  referralLogPageUri() {
    return `/marketing/referralLogs?q=${this.props.user.id_member}`;
  }

  newOrderPageUri() {
    return `/users/${this.props.user.id_member}/new-order/offer-selection`;
  }

  newFlightPageUri() {
    return `/users/${this.props.user.id_member}/new-flight-order/traveller`;
  }

  newGiftCardOrderPageUri() {
    return `/users/${this.props.user.id_member}/new-gift-card-order/gift-card-selection`;
  }

  newInsuranceOrderPageUri() {
    return `/users/${this.props.user.id_member}/new-insurance-order/insurance`;
  }

  newAtolPageUri() {
    return `/users/${this.props.user.id_member}/atol`;
  }

  newSentEmailUri() {
    return `/email/sent?email=${encodeURIComponent(this.props.user.email)}`;
  }

  handleBuyOfferChange = (event) => {
    this.setState({ offer_id: event.target.value });
  };

  handleBuyOfferSubmit = (event) => {
    const { history } = this.props;

    event.preventDefault();
    history.push(this.buyPageUri(this.state.offer_id));
  };

  updateUser = () => {
    this.UpdateUserForm.submitButton.click();
  };

  gdprRemoval = () => {
    this.setState({
      isGDPRRemovalModalVisible: true,
    });
  };

  gdprRequest = () => {
    this.setState({
      isGDPRRequestModalVisible: true,
    });
  };

  hideGDPRRemovalModal = () => {
    this.setState({
      isGDPRRemovalModalVisible: false,
    });
  };

  hideGDPRRequestModal = () => {
    this.setState({
      isGDPRRequestModalVisible: false,
    });
  };

  showCreatePromoCodeModal = () => {
    this.setState({
      isCreatePromoCodeModalVisible: true,
    });
  };

  showForceLogoutModal = () => {
    this.setState({
      isForceLogoutModalVisible: true,
    });
  };

  hideCreatePromoCodeModal = () => {
    this.setState({
      isCreatePromoCodeModalVisible: false,
    });
  };

  hideForceLogoutModal = () => {
    this.setState({
      isForceLogoutModalVisible: false,
    });
  };

  currentUserTenant = (user: App.User): App.Tenant => {
    return TENANTS[user.tenant];
  };

  showSpoofUserBlock = (tenant, user, userTenancies) => {
    if (tenant.value === TENANTS.scoopon.value) {
      const scooponUserId = userTenancies.find((el) => el.tenant === TENANTS.scoopon.value)?.id;
      return user.id_member === scooponUserId;
    }

    return validUserTenantForSiteTenant(tenant.value, user.tenant);
  };

  getFullTitle = () => {
    const { user } = this.props;

    const shadowBanned = user.shadow_ban_user ? ' Shadowbanned' : '';
    const vipClient = user.vip_client ? ' (VIP Client)' : '';
    const hasDash = shadowBanned || vipClient ? ' -' : '';

    return user.fullName + hasDash + shadowBanned + vipClient;
  };

  canRedeemLuxPlusBenefits = checkCanRedeemLuxPlusBenefits(this.props.subscriptions);
  luxPlusFreePreview = isFreePreviewLuxPlusMember(this.props.subscriptions);
  canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(this.props.subscriptions);
  hasLuxLoyaltyAccount = !!this.props.luxLoyaltyAccount;

  getSubtitle = () => {
    const luxPlusFreePreviewText = this.luxPlusFreePreview ? 'Free Preview ' : '';
    const luxPlusMemberText = this.canViewLuxPlusBenefits
      ? ` ${luxPlusFreePreviewText} ${LUX_PLUS.PROGRAM_NAME} Member`
      : '';

    const { luxLoyaltyAccount } = this.props;

    const content = [];

    if (luxPlusMemberText) {
      content.push(
        <Typography component="span" color={this.getLuxPlusColour()} fontSize="inherit" fontWeight={500}>
          {luxPlusMemberText}
        </Typography>,
      );
    }

    if (isLuxLoyaltyEnabled() && this.hasLuxLoyaltyAccount) {
      content.push(
        <LuxLoyaltyAccountChip
          tier={luxLoyaltyAccount.tier}
          luxLoyaltyProgramConfig={this.props.luxLoyaltyProgramConfig}
          fontSize="inherit"
        />,
      );
    }

    return (
      content.length > 0 && (
        <Stack direction="row" gap={2} alignItems="center">
          {content.map((item, idx) => (
            <>
              <React.Fragment key={idx}>{item}</React.Fragment>
              {content.length > 1 && idx !== content.length - 1 && (
                <Divider orientation="vertical" flexItem variant="middle" sx={{ borderColor: '#000' }} />
              )}
            </>
          ))}
        </Stack>
      )
    );
  };

  keyboardActions = [
    { id: 'Purchase', key: 'q', label: 'Purchase', action: () => this.props.history.push(this.purchasePageUri()) },
    { id: 'Create-Promo-Code', key: 'c', label: 'Create Promo Code', action: this.showCreatePromoCodeModal },
    { id: 'order', key: 'n', label: 'New Order', action: () => this.props.history.push(this.newOrderPageUri()) },
    {
      id: 'credit',
      key: 'm',
      label: 'Credits',
      action: () =>
        this.props.history.push(
          this.creditsPageUri(getRegionsByCountryCode(this.props.regions)[this.props.user.country_code]),
        ),
    },
    { id: 'flight', key: 'f', label: 'New Flight', action: () => this.props.history.push(this.newFlightPageUri()) },
    {
      id: 'giftcard',
      key: 'g',
      label: 'New Gift Card Order',
      action: () => this.props.history.push(this.newGiftCardOrderPageUri()),
    },
    { id: 'New-ATOL', key: 'a', label: 'New ATOL', action: () => this.props.history.push(this.newAtolPageUri()) },
    { id: 'Update-user', key: 's', label: 'Update User', action: this.updateUser },
    {
      id: 'custom-offer',
      key: 'o',
      label: 'New Custom Offer',
      action: () => this.props.history.push(newCustomOfferUri(this.props.user)),
    },
  ];

  handleKeyDown = (event) => {
    if (
      document.body === document.activeElement &&
      !event.ctrlKey &&
      !event.shiftKey &&
      !event.altKey &&
      !event.metaKey
    ) {
      event.preventDefault();

      const action = this.keyboardActions.find((action) => action.key === event.key);
      if (action) {
        action.action();
      }
    }
  };

  clearSearchParams() {
    this.props.history.replace({
      search: '',
    });
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    const regionsByCode = getRegionsByCountryCode(this.props.regions);
    const userCountry = regionsByCode[this.props.user.country_code];
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  findKeyById = (id) => {
    const existingAction = this.keyboardActions.find((action) => action.id === id);
    return existingAction?.key;
  };

  getLuxPlusColour = () => {
    if (this.luxPlusFreePreview) {
      return teal[500];
    } else if (this.canRedeemLuxPlusBenefits) {
      return blue[900];
    }
  };

  getLuxPlusLabel() {
    const { subscriptions } = this.props;
    return `${
      checkCanRedeemLuxPlusBenefits(subscriptions) || this.luxPlusFreePreview ? '' : `${subscriptions?.status} `
    }
    ${this.luxPlusFreePreview ? 'Free Preview ' : ''}
    ${LUX_PLUS.PROGRAM_NAME} Profile`;
  }

  render() {
    const {
      user,
      isAdmin,
      tenant,
      subscriptions,
      tenancy,
      luxLoyaltyAccount,
      luxLoyaltyProgramConfig,
      regions,
      schema,
      employeeUserList,
      currentSubscriptionOrder,
      currentSubscriptionOrderItem,
      updatePreferredAgent,
      update,
      refreshData,
    } = this.props;

    const { currentTab } = this.state;
    const regionsByCode = getRegionsByCountryCode(regions);
    const userCountry = regionsByCode[user.country_code];
    const fullTitle = this.getFullTitle();
    const subtitle = this.getSubtitle();
    const hasConfig = !!luxLoyaltyProgramConfig;
    const formattedProgramName = hasConfig ? titleCase(luxLoyaltyProgramConfig.programName) : 'Loyalty';

    const tabs: Record<TabKey, { label: string; showTab: boolean }> = {
      'user-details': {
        label: 'User Details',
        showTab: true,
      },
      quotes: {
        label: 'Quotes',
        showTab: canShowTabExceptRoles(this.context.user, [ROLE_EXPERIENCES_COORDINATOR, ROLE_ICS_STORE_TEAM]),
      },
      'custom-offers': {
        label: 'Custom offers & quotes',
        showTab: canShowTabExceptRoles(this.context.user, [ROLE_EXPERIENCES_COORDINATOR]),
      },
      'luxplus-member-profile': {
        label: this.getLuxPlusLabel(),
        showTab:
          hasLuxPlusSubscriptionStatus(subscriptions) &&
          canShowTabExceptRoles(this.context.user, [ROLE_EXPERIENCES_COORDINATOR, ROLE_ICS_STORE_TEAM]),
      },
      'luxloyalty-member-profile': {
        label: `${formattedProgramName} Profile`,
        showTab: !!luxLoyaltyAccount && canShowTabExceptRoles(user, [ROLE_EXPERIENCES_COORDINATOR]),
      },
      'user-agency': {
        label: 'Agency',
        showTab:
          tenant.value === 'leagenthub' &&
          canShowTabExceptRoles(user, [ROLE_EXPERIENCES_COORDINATOR, ROLE_ICS_STORE_TEAM]),
      },
      'recent-searches': {
        label: 'Recent Searches',
        showTab: canShowTabExceptRoles(this.context.user, [ROLE_ICS_STORE_TEAM, ROLE_ICS_STORE_TEAM]),
      },
      'recent-offer-page-views': {
        label: 'Recent Offer Page Views',
        showTab: canShowTabExceptRoles(this.context.user, [ROLE_ICS_STORE_TEAM]),
      },
      'abandoned-carts': {
        label: 'Abandoned Carts',
        showTab: canShowTabExceptRoles(this.context.user, [ROLE_ICS_STORE_TEAM]),
      },
    };

    const isTenantOfCurrentSite = validUserTenantForSiteTenant(tenant.value, user.tenant);
    const showSpoofActions = this.showSpoofUserBlock(tenant, user, tenancy);

    return (
      <>
        <Helmet>
          <title>Users | {user?.fullName || user.email}</title>
        </Helmet>

        <PageHeader
          user={user}
          color={this.getLuxPlusColour()}
          title={fullTitle}
          subtitle={subtitle}
          updatePreferredAgent={updatePreferredAgent}
          isAdmin={isAdmin}
          employeeUserList={employeeUserList}
        />

        <ResolveAccountTakeoverInstructions user={user} />

        <Stack direction="column" gap={2}>
          {isTenantOfCurrentSite && (
            <>
              {!userCountry && (
                <Alert severity="warning">
                  <strong>Error!</strong> There is no country set for this user. To view credits you need to add
                  country.
                </Alert>
              )}

              <Stack
                direction={{ xs: 'column', md: 'row' }}
                justifyContent={{ xs: 'start', md: 'space-between' }}
                gap={2}
              >
                <Stack direction="row" alignItems="start" flexWrap="wrap" gap={2}>
                  <UpgradeToLuxPlusButton
                    customer={user}
                    refreshData={refreshData}
                    canViewLuxPlusBenefits={this.canViewLuxPlusBenefits}
                  ></UpgradeToLuxPlusButton>

                  <Button component={Link} variant="contained" to={this.purchasePageUri()}>
                    Purchases
                    <SpeedNavigationKeyboardKey>{this.findKeyById('Purchase')}</SpeedNavigationKeyboardKey>
                  </Button>

                  <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM]}>
                    <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER]}>
                      <Button
                        component={Link}
                        variant="contained"
                        to={this.creditsPageUri(userCountry)}
                        disabled={!userCountry}
                      >
                        Credits
                        <SpeedNavigationKeyboardKey>{this.findKeyById('credit')}</SpeedNavigationKeyboardKey>
                      </Button>
                    </PermissionedComponent>

                    <Button
                      variant="contained"
                      component={Link}
                      className="T-new-order-btn"
                      to={this.newOrderPageUri()}
                    >
                      New Order
                      <SpeedNavigationKeyboardKey>{this.findKeyById('order')}</SpeedNavigationKeyboardKey>
                    </Button>

                    <PermissionedComponent
                      requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_EXPERIENCES_COORDINATOR]}
                    >
                      <Button variant="contained" onClick={this.showCreatePromoCodeModal}>
                        New Promo
                        <SpeedNavigationKeyboardKey>{this.findKeyById('Create-Promo-Code')}</SpeedNavigationKeyboardKey>
                      </Button>

                      {window.configs.OFFLINE_FLIGHT_PURCHASE_ENABLED === 'on' && (
                        <Button
                          variant="contained"
                          component={Link}
                          className="T-new-flight-btn"
                          to={this.newFlightPageUri()}
                        >
                          New Flight
                          <SpeedNavigationKeyboardKey>{this.findKeyById('flight')}</SpeedNavigationKeyboardKey>
                        </Button>
                      )}

                      {tenant.brand === 'luxuryescapes' && (
                        <Button variant="contained" component={Link} to={this.newGiftCardOrderPageUri()}>
                          New Gift Card
                          <SpeedNavigationKeyboardKey>{this.findKeyById('giftcard')}</SpeedNavigationKeyboardKey>
                        </Button>
                      )}

                      <Button variant="contained" component={Link} to={this.promoRequestPageUri()}>
                        Promo Requests
                      </Button>

                      <Button variant="contained" component={Link} to={this.referralLogPageUri()}>
                        Referrals
                      </Button>
                      <Button variant="contained" component={Link} to={this.newAtolPageUri()}>
                        New Atol Email
                        <SpeedNavigationKeyboardKey>{this.findKeyById('New-ATOL')}</SpeedNavigationKeyboardKey>
                      </Button>

                      <Button variant="contained" component={Link} to={this.newSentEmailUri()}>
                        Sent Emails
                      </Button>
                    </PermissionedComponent>
                  </PermissionedComponent>
                </Stack>

                <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER]}>
                  <Stack direction="row" alignItems="start" flexWrap="wrap" flexShrink="0" gap={2}>
                    <Button variant="contained" color="error" onClick={this.showForceLogoutModal}>
                      Force logout
                    </Button>

                    <PermissionedComponent>
                      <Button variant="contained" color="error" onClick={this.gdprRemoval}>
                        DELETE ACCOUNT
                      </Button>
                    </PermissionedComponent>

                    <Button variant="contained" color="error" onClick={this.gdprRequest}>
                      REQUEST ACCOUNT DELETION
                    </Button>
                  </Stack>
                </PermissionedComponent>
              </Stack>

              <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM]}>
                <Stack direction="row" alignItems="start" flexWrap="wrap" gap={2}>
                  <Button variant="contained" component={Link} to={newCustomOfferUri(user)}>
                    New custom offer
                    <SpeedNavigationKeyboardKey>{this.findKeyById('custom-offer')}</SpeedNavigationKeyboardKey>
                  </Button>
                  <Button variant="contained" component={Link} to={historyUri(user)}>
                    History
                  </Button>
                  <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER]}>
                    <PartnershipButtons user={user} />
                  </PermissionedComponent>
                </Stack>
              </PermissionedComponent>
            </>
          )}

          <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM]}>
            <Stack direction="row" alignItems="start" flexWrap="wrap" gap={2}>
              {showSpoofActions && <SpoofUserBlock user={user} tenant={tenant} />}
              {tenant.value === 'lebusinesstraveller' && showSpoofActions && <BusinessAdminSpoofButton user={user} />}
              {!isTenantOfCurrentSite && (
                <Alert severity="warning">
                  <TenancyWarningMessage tenant={tenant} userTenant={this.currentUserTenant(user)} />
                </Alert>
              )}
            </Stack>
          </PermissionedComponent>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} onChange={(_, value) => this.setCurrentTab(value)}>
              {Object.entries(tabs).map(
                ([tab, options]) => options.showTab && <Tab key={tab} value={tab} label={options.label} />,
              )}
            </Tabs>
          </Box>

          <Box>
            {currentTab === 'custom-offers' && <CustomOffersTab user={user} />}
            {currentTab === 'quotes' && <QuotesTab user={user} />}
            {currentTab === 'user-details' && (
              <>
                <UpdateUser
                  schema={schema.put}
                  brand={tenant.brand}
                  update={update}
                  user={user}
                  ref={(UpdateUserForm) => {
                    this.UpdateUserForm = UpdateUserForm;
                  }}
                />

                <dl>
                  <dt className="h6 text-muted text-uppercase">Account created</dt>
                  <dd>{formatDateOrdinalWithClock(user.created_date)}</dd>
                </dl>
                <UserLoginTable userId={user.memberId} />

                <Typography variant="body1" color="textSecondary">
                  Email changes history
                </Typography>
                <UserEmailChangeTable userId={user.memberId} />

                <Typography variant="body1" color="textSecondary">
                  Phone number changes history
                </Typography>
                <UserPhoneNumberChangeTable userId={user.memberId} />

                {user.signup_domain && (
                  <dl>
                    <dt className="h6 text-muted text-uppercase">Signup Domain</dt>
                    <dd>{user.signup_domain}</dd>
                  </dl>
                )}

                {tenancy && tenancy.length > 0 && (
                  <dl>
                    <dt className="h6 text-muted text-uppercase">Other domains that have user with same email</dt>
                    {tenancy.map((el) => (
                      <dd key={el.tenant}>{el.tenant}</dd>
                    ))}
                  </dl>
                )}

                <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER]}>
                  <Box className="button-container">
                    <Button variant="contained" onClick={this.updateUser}>
                      Update
                    </Button>
                  </Box>
                </PermissionedComponent>
              </>
            )}

            {currentTab === 'luxplus-member-profile' && (
              <MembershipSubscriptionDetails
                subscriptions={subscriptions}
                customerId={user.id_member}
                currentSubscriptionOrderItem={currentSubscriptionOrderItem}
                currentSubscriptionOrder={currentSubscriptionOrder}
                refreshData={refreshData}
              />
            )}

            {currentTab === 'luxloyalty-member-profile' && (
              <LuxLoyaltyAccountDetails
                account={luxLoyaltyAccount}
                customerId={user.id_member}
                luxLoyaltyProgramConfig={luxLoyaltyProgramConfig}
              />
            )}

            {currentTab === 'user-agency' && <UserAgencyDetails id={user.id_member}></UserAgencyDetails>}
            {currentTab === 'recent-searches' && (
              <UserRecentSearchTable memberId={user.memberId} memberEmail={user.email} tenant={tenant} />
            )}
            {currentTab === 'recent-offer-page-views' && <UserRecentOfferPageViewsTable userId={user.memberId} />}
            {currentTab === 'abandoned-carts' && (
              <UserAbandonedCartsTable
                userId={user.memberId}
                email={user.email}
                region={user.country_code}
                tenant={tenant}
              />
            )}
          </Box>

          <CreatePromoForUserModal
            isModalOpen={this.state.isCreatePromoCodeModalVisible}
            closeModal={this.hideCreatePromoCodeModal}
            user={user}
          />
          <GDPRRemovalModal
            isGDPRRemovalModalVisible={this.state.isGDPRRemovalModalVisible}
            hideGDPRRemovalModal={this.hideGDPRRemovalModal}
            memberId={user.id_member}
            email={user.email}
            currencyCode={userCountry?.currencyCode}
            brand={tenant.brand}
          />
          <GDPRRequestModal
            isGDPRRequestModalVisible={this.state.isGDPRRequestModalVisible}
            hideGDPRRequestModal={this.hideGDPRRequestModal}
            memberId={user.id_member}
            email={user.email}
            currencyCode={userCountry?.currencyCode}
            brand={tenant.brand}
          />
          <ForceLogoutModal
            isOpen={this.state.isForceLogoutModalVisible}
            user={user}
            onClose={this.hideForceLogoutModal}
          />
        </Stack>
      </>
    );
  }
}

UserDetails.contextTypes = {
  user: PropTypes.object,
};

export const UserDetailsComponent = UserDetails;

export default withTenant(withRouter(UserDetails));
