import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import requestGetHeroPlannerSchedule from '~/queries/customerCommunication/requestGetHeroPlannerSchedule';
import requestPostHeroPlannerSchedule from '~/queries/customerCommunication/requestPostHeroPlannerSchedule';

import { Alert, AlertTitle, Button, CircularProgress } from '@mui/material';

import { RequestStatus } from '~/consts/requestConstants';

import { getDayJs } from '~/services/TimeService';

import { isRequestInitial, isRequestPending, isRequestRejected } from '~/utils/requestUtils';

import ScheduleEntityForm from './ScheduleEntityForm';

interface Props {
  scheduleId?: string;
}

const EMPTY_SCHEDULE: CustomerCommunication.UnsavedHeroPlannerSchedule = {
  sendDate: getDayJs().add(1, 'day').tz(window.configs.DEFAULT_TIMEZONE),
  brandId: '',
  countryGroupId: '',
  countryId: '',
  stateId: '',
  cityId: '',
  segmentId: '',
  cadenceId: '',
  membershipId: '',

  isApprovedForTpfm: false,
  emailSubjectLine: '',
  emailPreHeader: '',
  emailBannerId: undefined,
  emailBannerPosition2Id: undefined,
  emailHeroOfferIds: [],
  pushTitle: '',
  pushSubtitle: '',
  pushMessage: '',
  pushLink: '',
  pushSvcImageId: '',
};

function NewScheduleForm(props: Props) {
  const { scheduleId } = props;
  const history = useHistory();

  const [existingScheduleReq, setExistingScheduleReq] = useState<
    Utils.RequestState<CustomerCommunication.UnsavedHeroPlannerSchedule, string>
  >({
    status: RequestStatus.INITIAL,
  });
  const requestExistingSchedule = useCallback(async () => {
    setExistingScheduleReq({
      status: RequestStatus.PENDING,
      params: scheduleId,
    });

    try {
      const result = await requestGetHeroPlannerSchedule(scheduleId);
      const { id, ...unsaved } = result;
      setExistingScheduleReq({
        status: RequestStatus.FULFILLED,
        params: scheduleId,
        result: unsaved as CustomerCommunication.UnsavedHeroPlannerSchedule,
      });
    } catch (error) {
      setExistingScheduleReq({
        status: RequestStatus.REJECTED,
        params: scheduleId,
        error,
      });
    }
  }, [scheduleId]);

  useEffect(() => {
    if (scheduleId) {
      requestExistingSchedule();
    }
  }, [requestExistingSchedule, scheduleId]);

  const [submissionReq, setSubmissionReq] = useState<
    Utils.RequestState<
      CustomerCommunication.HeroPlannerSchedule,
      string,
      CustomerCommunication.UnsavedHeroPlannerSchedule
    >
  >({
    status: RequestStatus.INITIAL,
  });
  const handleSubmission = useCallback(
    async (unsaved: CustomerCommunication.UnsavedHeroPlannerSchedule) => {
      setSubmissionReq({
        status: RequestStatus.PENDING,
        params: unsaved,
      });

      try {
        const createdHeroPlannerSchedule = await requestPostHeroPlannerSchedule(unsaved);
        setSubmissionReq({
          status: RequestStatus.FULFILLED,
          params: unsaved,
          result: createdHeroPlannerSchedule,
        });
        history.push(`/customer-communication/hero-planner/schedules/${createdHeroPlannerSchedule.id}`);
      } catch (error) {
        setSubmissionReq({
          status: RequestStatus.REJECTED,
          params: unsaved,
          error,
        });
      }
    },
    [history],
  );

  if ((!!scheduleId && isRequestInitial(existingScheduleReq)) || isRequestPending(existingScheduleReq)) {
    return <CircularProgress />;
  }

  if (isRequestRejected(existingScheduleReq)) {
    return (
      <Alert
        severity="error"
        action={
          <Button size="small" color="inherit" onClick={requestExistingSchedule}>
            Retry
          </Button>
        }
      >
        <AlertTitle>Could not fetch the schedule.</AlertTitle>
        <pre>{existingScheduleReq.error}</pre>
      </Alert>
    );
  }

  return (
    <>
      <ScheduleEntityForm
        editorType="new"
        schedule={existingScheduleReq.result ?? EMPTY_SCHEDULE}
        busy={isRequestPending(submissionReq)}
        onSubmit={handleSubmission}
      />
      {isRequestRejected(submissionReq) && (
        <Alert severity="error">
          <AlertTitle>Please resolve the following:</AlertTitle>
          <pre>{submissionReq.error}</pre>
        </Alert>
      )}
    </>
  );
}

export default NewScheduleForm;
