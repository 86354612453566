import React, { useEffect, useMemo } from 'react';

import { sum } from 'lodash';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { selectBedbankRefundPolicies } from '~/selectors/bedbankSelector';

import { ContentCopy } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, IconButton, Link, Typography } from '@mui/material';

import { Order } from '@luxuryescapes/contract-svc-order';

import { fetchBedbankReservation } from '~/actions/bedbank';

import CustomOfferRebookingOrderBanner from '~/components/Common/CustomOfferRebookingOrderBanner';
import PermissionedComponent from '~/components/Common/PermissionedComponent';
import Spinner from '~/components/Common/Spinner';

import { BEDBANK_SUPPLIER_INFO } from '~/consts/bedbank';
import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import { useAppSelector } from '~/hooks/store';

import { titleCase } from '~/utils/stringUtils';

import dateFormatter from '../Home/formatters/dateFormatter';

import OrderBedbankItemFacilities from './BedbankList/Facilities';
import OrderBedbankItemFinePrint from './BedbankList/FinePrint';
import OrderBedbankItem from './BedbankList/List';
import Log from './OrderItem/Log';
import Supplier from './OrderItem/Supplier';
import ResendEmail from './ResendEmail';

type Props = {
  order: App.Order;
  item: Order.BedbankItem;
  hasAllowedRefund: boolean;
  bedbankRoomsInfo: {
    [reservationRoomId: string]: {
      id: string;
      status: string;
      reservationStatus: string;
      reservationRoomStatus: string;
      rebook?: App.Bedbank.ReservationRebookInfo;
      info?: App.Bedbank.ReservationRoomInfo;
    };
  };
  customerEmailToAgentState: Record<string, unknown> & { isSending: boolean };
  emailToCustomerState: Record<string, unknown> & { isSending: boolean };
  rebookableLoading: boolean;
  resendCustomerEmail: (offerId: string, offerType?: string) => void;
  resendCustomerEmailToAgent: (offerId: string, offerType?: string) => void;
  showRefundModal: (value: unknown) => void;
  showRebookingModal: (value: unknown) => void;
  showReconfirmModal: (value: unknown) => void;
  shouldShowCancellationWarning: boolean;
  checkRebookable: () => void;
};

export default function OrderDetailBedbank({
  order,
  item,
  hasAllowedRefund,
  bedbankRoomsInfo,
  customerEmailToAgentState,
  emailToCustomerState,
  rebookableLoading,
  resendCustomerEmail,
  resendCustomerEmailToAgent,
  showRefundModal,
  showRebookingModal,
  showReconfirmModal,
  shouldShowCancellationWarning,
  checkRebookable,
}: Props) {
  const tenant = useSelector((state: App.State) => state.tenant);

  const { enqueueSnackbar } = useSnackbar();

  const handleCopyToClipboard = (info: string) => {
    navigator.clipboard.writeText(info);
    enqueueSnackbar('Copied to clipboard', { variant: 'success', autoHideDuration: 2000 });
  };

  const [isRebookable, marginIncAud] = useMemo(() => {
    const rebookable = (item.rooms ?? []).every(
      (room) => bedbankRoomsInfo[room.id_reservation_room] && bedbankRoomsInfo[room.id_reservation_room]?.rebook,
    );
    const marginInc = sum(
      (item.rooms ?? []).map((r) => bedbankRoomsInfo[r.id_reservation_room]?.rebook?.marginIncreaseInAUD ?? 0),
    );
    return [rebookable, marginInc];
  }, [item, bedbankRoomsInfo]);

  const isNotFinished = useMemo(() => {
    return (
      item.status === 'cancelled' &&
      order.status === 'pending' &&
      (item.rooms ?? []).every(
        (room) =>
          room.status === 'pending' &&
          bedbankRoomsInfo[room.id_reservation_room] &&
          bedbankRoomsInfo[room.id_reservation_room]?.reservationStatus === 'failed',
      )
    );
  }, [item, order, bedbankRoomsInfo]);

  const reservationId = item.id_reservation;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBedbankReservation(reservationId));
  }, [reservationId, dispatch]);

  const refundPolicies = useAppSelector((state) => selectBedbankRefundPolicies(state, reservationId));

  const supplierKey = (item.supplier ?? 'expedia').toLowerCase();
  const supplierInfo = BEDBANK_SUPPLIER_INFO[supplierKey];

  if (rebookableLoading) {
    return (
      <Dialog open>
        <DialogContent>
          <Spinner />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Box
      key={item.id}
      sx={{
        border: '1px solid',
        borderColor: 'grey.100',
        borderRadius: '8px',
        marginTop: 2,
        marginBottom: '20px',
      }}
    >
      <Box bgcolor="grey.50" p={2}>
        <Box display="grid" columnGap={2} gridTemplateColumns="auto 1fr" alignItems="center">
          <Typography color="black">Order Item ID:</Typography>
          <Typography>{item.id}</Typography>

          <Typography color="black">LE Booking Number:</Typography>
          <Typography>
            {item.booking_number}{' '}
            <IconButton
              title="Copy booking number"
              onClick={() => {
                handleCopyToClipboard(item.booking_number);
              }}
              size="small"
            >
              <ContentCopy fontSize="small" />
            </IconButton>
          </Typography>

          <Typography color="black">Bedbank Reservation ID:</Typography>
          <Typography>
            {reservationId}{' '}
            <IconButton
              title="Copy reservation id"
              onClick={() => {
                handleCopyToClipboard(reservationId);
              }}
              size="small"
            >
              <ContentCopy fontSize="small" />
            </IconButton>
          </Typography>

          <Typography color="black">Supplier:</Typography>
          <Typography>{titleCase(item.supplier ?? 'expedia')}</Typography>

          <Typography color="black">Supplier Booking Number:</Typography>
          <Typography>{item.supplier_booking_number ?? item.booking_number}</Typography>

          <Typography color="black">Reservation Email:</Typography>
          <Typography>{supplierInfo?.email ?? 'N/A'}</Typography>

          <Typography color="black">Reservation Phone:</Typography>
          <Typography>{supplierInfo?.phone ?? 'N/A'}</Typography>

          {supplierInfo?.portal && (
            <>
              <Typography color="black">Portal:</Typography>
              <Link href={supplierInfo.portal} target="_blank" rel="noopener noreferrer">
                {supplierInfo.portal}
              </Link>
            </>
          )}

          {item.parent_booking_number && (
            <>
              <Typography color="black">Parent Booking Number:</Typography>
              <Typography>{item.parent_booking_number}</Typography>
            </>
          )}
        </Box>

        {isNotFinished && (
          <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER]}>
            <Box display="grid" columnGap={2} gridTemplateColumns="repeat(2, auto) 1fr" alignItems="center">
              <Typography color="black">Supplier reservation is not confirmed:</Typography>
              <Button variant="text" size="small" onClick={() => showReconfirmModal({ itemId: item.id })}>
                Reconfirm
              </Button>
            </Box>
          </PermissionedComponent>
        )}

        <Box display="grid" columnGap={2} gridTemplateColumns="repeat(3, auto) 1fr" alignItems="center">
          <Typography color="black">Rebooking:</Typography>
          <Button variant="text" size="small" onClick={() => checkRebookable()}>
            Check
          </Button>
          {isRebookable && (
            <Button variant="text" size="small" onClick={() => showRebookingModal({ itemId: item.id })}>
              Rebook (+${marginIncAud})
            </Button>
          )}
        </Box>

        {item.parent_reservation_id && (
          <Box display="grid" columnGap={2} gridTemplateColumns="repeat(2, auto) 1fr" alignItems="center">
            <Typography color="black">Rebooked:</Typography>
            <Typography>{dateFormatter(item.rebooked_at)}</Typography>
          </Box>
        )}
        <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM]}>
          <Box maxWidth="600px">
            <ResendEmail
              emailType="customer"
              resendEmail={resendCustomerEmail}
              isCustomerEmailDisabled={emailToCustomerState.isSending}
              resendEmailToAgent={resendCustomerEmailToAgent}
              isAgentEmailDisabled={customerEmailToAgentState.isSending}
            />
          </Box>
        </PermissionedComponent>
      </Box>

      <CustomOfferRebookingOrderBanner order={order} />

      <Box p={2}>
        <OrderBedbankItem
          order={order}
          tenant={tenant}
          showRefundModal={showRefundModal}
          hasAllowedRefund={hasAllowedRefund}
          item={item}
          bedbankRoomsInfo={bedbankRoomsInfo}
          disableInteraction={false}
          shouldShowCancellationWarning={shouldShowCancellationWarning}
          refundPolicies={refundPolicies}
        />
      </Box>

      <Box p={3}>
        <Supplier reservationId={item.id_reservation} />
        {item.offer?.room?.facility_groups && (
          <OrderBedbankItemFacilities name="Room Facilities" facilityGroups={item.offer.room.facility_groups} />
        )}
        {item.offer?.facility_groups && (
          <OrderBedbankItemFacilities name="Hotel Facilities" facilityGroups={item.offer.facility_groups} />
        )}
        {item.offer?.fine_print && <OrderBedbankItemFinePrint finePrint={item.offer.fine_print} />}
        <Log orderId={item.fk_order_id} itemId={item.id} />
      </Box>
    </Box>
  );
}
