import React, { ComponentProps, useCallback, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router';

import { Stack } from '@mui/material';

import { AccommodationTabs } from '~/components/Accommodation/Pages/Home/AccommodationHomePage';
import AccommodationPropertiesSearchForm from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesSearchForm';
import AccommodationUnmappedSuppliersResultsTable from '~/components/Accommodation/Pages/Properties/components/AccommodationUnmappedSuppliersResultsTable';
import usePagedSearch from '~/components/Accommodation/hooks/usePagedSearch';

interface Props {
  selectedTab: AccommodationTabs;
  refreshProperties: () => void;
}

export default function AccommodationUnmappedSuppliersResultsContainer({ refreshProperties }: Props) {
  const history = useHistory();
  const location = useLocation();

  const handleSearchFormSubmission = useCallback<ComponentProps<typeof AccommodationPropertiesSearchForm>['onSubmit']>(
    (formObject) => {
      const urlSearchParamsToPush = new URLSearchParams(formObject);
      history.push({
        search: urlSearchParamsToPush.toString(),
      });
    },
    [history],
  );

  const urlSearchParams = useMemo<URLSearchParams>(() => new URLSearchParams(location.search), [location.search]);

  const { searchInput, pageNumber } = usePagedSearch(urlSearchParams);

  const handlePageChange = useCallback(
    (pageIndex: number) => {
      const urlSearchParamsToPush = new URLSearchParams(urlSearchParams);
      if (pageIndex) {
        urlSearchParamsToPush.set('page', String(pageIndex + 1));
      } else {
        urlSearchParamsToPush.delete('page');
      }
      history.push({
        search: urlSearchParamsToPush.toString(),
      });
    },
    [urlSearchParams, history],
  );

  return (
    <Stack direction="column" spacing={2}>
      <AccommodationPropertiesSearchForm
        initialInput={searchInput}
        onSubmit={handleSearchFormSubmission}
        refreshProperties={refreshProperties}
        selectedTab={AccommodationTabs.UNMAPPED_SUPPLIERS}
      />
      <AccommodationUnmappedSuppliersResultsTable
        searchInput={searchInput}
        pageIndex={pageNumber - 1}
        onPageChange={handlePageChange}
      />
    </Stack>
  );
}
