import React, { useEffect, useState } from 'react';

import { Discount } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SellIcon from '@mui/icons-material/Sell';
import { Box, Button, Collapse, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';
import { currencyFormatter } from '~/components/Experiences/helpers';

import useToggleState from '~/hooks/useToggleState';

import { getFXRates } from '~/services/ReportingService';

import { isLTLEHotelType, isOfferTypeDynamicPricedHotel, isOfferTypeTour } from '~/utils/offer';
import presentFXRates from '~/utils/presentFXRates';

import PriceSummaryField from './PriceSummaryField';
import {
  convertToAUD,
  getChannelManagerRate,
  getChargedPrice,
  getCostCurrencyCode,
  getCostTaxesAndFees,
  getMemberPrice,
  getNonMemberPrice,
  getPackageCostPrice,
  getTaxesAndFees,
  getTotalCostPrice,
  getTotalPrice,
} from './utils';

interface Props {
  item: App.OrderItem;
  currencyCode: string;
}

export default function PricingSummary({ item, currencyCode }: Props) {
  const { isToggled: isSurchargesVisible, toggle: toggleSurcharges } = useToggleState(false);
  const [fxRates, setFxRates] = useState(null);

  useEffect(() => {
    getFXRates().then((fxRates) => setFxRates(presentFXRates(fxRates)));
  }, []);

  const { reservation, offer_package: offerPackage } = item;

  const chargedPrice = getTotalPrice(item);

  const taxesAndFees = getTaxesAndFees(item);

  const costTaxesAndFees = getCostTaxesAndFees(item);

  const channelManagerRate = getChannelManagerRate(item);

  const packageCostPrice = getPackageCostPrice(offerPackage, item);

  const totalCostPrice = getTotalCostPrice(packageCostPrice, reservation);

  const packagePrice = getChargedPrice(offerPackage, item);

  const memberPrice = getMemberPrice(offerPackage, item);

  const nonMemberPrice = getNonMemberPrice(offerPackage, item);

  const showLuxPlusPrice = item.subscriber_tier && offerPackage.lux_plus_price > 0;

  // Show total with taxes and surcharges included for LTLE hotels only
  // we will update display for other hotel types later
  const totalCostPriceDisplayed = isLTLEHotelType(item.offer) ? totalCostPrice : packageCostPrice;

  const costCurrencyCode = getCostCurrencyCode(offerPackage, item);

  const buttonMessage = isSurchargesVisible ? 'Hide' : 'Show';

  let breakDownMessage = null;

  if (reservation?.extra_guest_surcharge_amount > 0) {
    breakDownMessage = 'Extra guest surcharges apply';
  }

  if (reservation?.peak_period_surcharge_amount > 0) {
    breakDownMessage = 'Surcharges apply';
  }

  if (reservation?.extra_guest_surcharge_amount > 0 && reservation?.peak_period_surcharge_amount > 0) {
    breakDownMessage = 'Surcharges and extra guest surcharges apply';
  }

  if (!fxRates) {
    return <Spinner size={26} />;
  }

  return (
    <Box>
      <Collapse in={!isSurchargesVisible} timeout="auto" unmountOnExit>
        <Typography variant="subtitle1" fontWeight="bold">
          Pricing
        </Typography>

        <Box display="grid" columnGap={1} gridTemplateColumns="auto 1fr" alignItems="center" mt={1}>
          {item.promo_code_discount_amount > 0 && (
            <>
              <Discount fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Promo Code Discount Amount"
                value={currencyFormatter(currencyCode, -item.promo_code_discount_amount)}
                priceType="discount"
              />
            </>
          )}
          <SellIcon fontSize="small" color="secondary" />
          <PriceSummaryField boldPrice label="Total sell price" value={currencyFormatter(currencyCode, chargedPrice)} />

          <CreditCardIcon fontSize="small" color="secondary" />
          <PriceSummaryField
            boldPrice
            label="Total cost price"
            value={currencyFormatter(costCurrencyCode, totalCostPriceDisplayed)}
          />
          {showLuxPlusPrice && (
            <>
              <SellIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                boldPrice
                label="LuxPlus Member price"
                value={currencyFormatter(currencyCode, memberPrice)}
              />
              <SellIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                boldPrice
                label="Public price"
                value={currencyFormatter(currencyCode, nonMemberPrice)}
              />
            </>
          )}
          {reservation?.waived_surcharge_amount && (
            <>
              <AddCircleIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Waived surcharge"
                value={currencyFormatter(costCurrencyCode, reservation?.waived_surcharge_amount)}
              />
            </>
          )}

          {isOfferTypeDynamicPricedHotel(item.offer) && (
            <>
              <CorporateFareIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Channel Manager Rate"
                value={currencyFormatter(costCurrencyCode, channelManagerRate)}
              />
            </>
          )}

          {isOfferTypeTour(item.offer) && taxesAndFees > 0 && (
            <>
              <MonetizationOnIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Also includes Taxes & Fees"
                value={currencyFormatter(currencyCode, taxesAndFees)}
              />
            </>
          )}
        </Box>

        <Typography mt={1}>{breakDownMessage}</Typography>
      </Collapse>

      <Collapse in={isSurchargesVisible} timeout="auto" unmountOnExit>
        <Typography variant="subtitle1" fontWeight="bold">
          Pricing Sell
        </Typography>

        <Box display="grid" columnGap={1} gridTemplateColumns="auto 1fr" alignItems="center" mt={1}>
          <SellIcon fontSize="small" color="secondary" />
          <PriceSummaryField boldPrice label="Total sell price" value={currencyFormatter(currencyCode, chargedPrice)} />

          {item.promo_code_discount_amount > 0 && (
            <>
              <Discount fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Promo Code Discount Amount"
                value={currencyFormatter(currencyCode, -item.promo_code_discount_amount)}
                priceType="discount"
              />
            </>
          )}

          <AddCircleIcon fontSize="small" color="secondary" />
          <PriceSummaryField label="Package price" value={currencyFormatter(currencyCode, packagePrice)} />

          {reservation?.surcharge_internal_amount > 0 && (
            <>
              <AddCircleIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Revenue management surcharge (sell)"
                value={currencyFormatter(currencyCode, reservation?.surcharge_internal_amount)}
              />
            </>
          )}
          {reservation && reservation?.peak_period_surcharge_amount > 0 && (
            <>
              <AddCircleIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Peak period surcharge (sell)"
                value={currencyFormatter(currencyCode, reservation?.peak_period_surcharge_amount)}
              />
            </>
          )}
          {reservation && reservation?.extra_guest_surcharge_amount > 0 && (
            <>
              <AddCircleIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Extra guest surcharge (sell)"
                value={currencyFormatter(currencyCode, reservation?.extra_guest_surcharge_amount)}
              />
            </>
          )}

          {taxesAndFees > 0 && (
            <>
              <AddCircleIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Also includes Taxes & Fees"
                value={currencyFormatter(currencyCode, taxesAndFees)}
              />
            </>
          )}

          {currencyCode !== 'AUD' && (
            <>
              <SellIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Total sell price in AUD"
                value={currencyFormatter('AUD', convertToAUD(fxRates, currencyCode, chargedPrice).toFixed())}
              />
            </>
          )}

          <Box gridColumn="span 2" mt={2} mb={1}>
            <Typography variant="subtitle1" fontWeight="bold">
              Pricing Cost
            </Typography>
          </Box>

          {item.promo_code_discount_amount > 0 && (
            <>
              <Discount fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Promo Code Discount Amount"
                value={currencyFormatter(currencyCode, -item.promo_code_discount_amount)}
                priceType="discount"
              />
            </>
          )}

          <>
            <CreditCardIcon fontSize="small" color="secondary" />
            <PriceSummaryField
              boldPrice
              label="Total cost Price"
              value={currencyFormatter(costCurrencyCode, totalCostPrice)}
            />
          </>
          <>
            <AddCircleIcon fontSize="small" color="secondary" />
            <PriceSummaryField
              label="Package cost Price"
              value={currencyFormatter(costCurrencyCode, packageCostPrice)}
            />
          </>
          {reservation && reservation?.peak_period_surcharge_cost > 0 && (
            <>
              <AddCircleIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Peak period surcharge (cost)"
                value={currencyFormatter(costCurrencyCode, reservation?.peak_period_surcharge_cost)}
              />
            </>
          )}
          {reservation && reservation?.extra_guest_surcharge_cost > 0 && (
            <>
              <AddCircleIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Extra guest surcharge (cost)"
                value={currencyFormatter(costCurrencyCode, reservation?.extra_guest_surcharge_cost)}
              />
            </>
          )}
          {costTaxesAndFees > 0 && (
            <>
              <AddCircleIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Also includes Taxes & Fees"
                value={currencyFormatter(costCurrencyCode, costTaxesAndFees)}
              />
            </>
          )}
          {costCurrencyCode !== 'AUD' && (
            <>
              <CreditCardIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Package cost price in AUD"
                value={currencyFormatter('AUD', convertToAUD(fxRates, costCurrencyCode, packageCostPrice).toFixed())}
              />
              <CreditCardIcon fontSize="small" color="secondary" />
              <PriceSummaryField
                label="Total cost price in AUD"
                value={currencyFormatter('AUD', convertToAUD(fxRates, costCurrencyCode, totalCostPrice).toFixed())}
              />
            </>
          )}
        </Box>
      </Collapse>

      {isLTLEHotelType(item.offer) && (
        <Box textAlign="right">
          <Button onClick={toggleSurcharges} variant="text" size="small">
            {buttonMessage} pricing breakdown
          </Button>
        </Box>
      )}
    </Box>
  );
}
