import React, { useCallback, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router';

import PotentialMappingsResultsTable from './PotentialMappingsResultsTable';

export default function PotentialMappingsResultsContainer() {
  const history = useHistory();
  const location = useLocation();

  const urlSearchParams = useMemo<URLSearchParams>(() => new URLSearchParams(location.search), [location.search]);

  const { pageNumber } = useMemo(() => {
    const pageFromSearchParams = urlSearchParams.get('page');
    return {
      pageNumber: pageFromSearchParams ? Number(pageFromSearchParams) : 1,
    };
  }, [urlSearchParams]);

  const handlePageChange = useCallback(
    (pageIndex: number) => {
      const urlSearchParamsToPush = new URLSearchParams(urlSearchParams);
      if (pageIndex) {
        urlSearchParamsToPush.set('page', String(pageIndex + 1));
      } else {
        urlSearchParamsToPush.delete('page');
      }
      history.push({
        search: urlSearchParamsToPush.toString(),
      });
    },
    [urlSearchParams, history],
  );

  return <PotentialMappingsResultsTable pageIndex={pageNumber - 1} onPageChange={handlePageChange} />;
}
