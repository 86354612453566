import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { theme } from '~/theme';

import { Box, Typography } from '@mui/material';

import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';

import { getTermsAndConditions } from '~/services/CartService';
import { formatDateOrdinalWithClock } from '~/services/TimeService';

interface Props {
  orderId: string;
}

const getTermsAndConditionsStatusColor = (termsAndConditions: App.TermsAndConditions) => {
  return termsAndConditions.accepted ? `${theme.palette.success.main}` : `${theme.palette.warning.main}`;
};

export function TermsAndConditionsStatus({ orderId }: Props) {
  const [termsAndConditions, setTermsAndConditions] = useState<App.TermsAndConditions | undefined>();

  useEffect(() => {
    getTermsAndConditions(orderId)
      .then((results) => {
        if (results && results.length > 0) {
          setTermsAndConditions(results[0]);
        }
      })
      .catch((e) => {
        console.error('Error fetching terms and conditions: ', e);
        setTermsAndConditions(undefined);
      });
  }, [orderId]);

  if (!termsAndConditions?.id) {
    return null;
  }

  return (
    <Box>
      <DefinitionTitle>Terms and Conditions</DefinitionTitle>
      <Typography color={getTermsAndConditionsStatusColor(termsAndConditions)}>
        Status: {termsAndConditions.accepted ? 'Acknowledged' : 'Pending acknowledgement'}
      </Typography>
      <Typography>Sent at: {formatDateOrdinalWithClock(termsAndConditions.created_at)}</Typography>
      {termsAndConditions.accepted && (
        <DefinitionText>Accepted at: {formatDateOrdinalWithClock(termsAndConditions.accepted_at)}</DefinitionText>
      )}
      {termsAndConditions.email_id && (
        <DefinitionText>
          <Link to={`/email/render?id=${termsAndConditions.email_id}`}>View email</Link>
        </DefinitionText>
      )}
    </Box>
  );
}
