import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';

import { Check, Close } from '@mui/icons-material';
import { Alert, Box, IconButton, Link, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import InternalPropertyLink from '~/components/Accommodation/Pages/Properties/components/InternalPropertyLink';
import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';
import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import { MappedInternalProperty, getPotentialMappings, updateMapping } from '~/services/AccommodationService';

interface Props {
  pageIndex?: number;
  onPageChange: (pageIndex: number) => void;
}

const PAGE_SIZE = 20;

export default function PotentialMappingsResultsTable({ pageIndex, onPageChange }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const showConfirmDialog = useConfirmDialog();
  const getProperties = useCallback(
    () =>
      getPotentialMappings({
        page: String((pageIndex ?? 0) + 1),
        limit: String(PAGE_SIZE),
      }),
    [pageIndex],
  );

  const { fetchReqState, refetch } = useAccommodationServiceFetch({
    fetchFn: getProperties,
  });

  const handleVerify = useCallback(
    (propertyId: string, mappedPropertyId: string) => async () => {
      const confirmed = await showConfirmDialog({
        title: 'Verify Mapping?',
        description: `This will verify the mapping, are you sure you want to proceed?`,
      });
      if (!confirmed) {
        return;
      }

      try {
        await updateMapping({
          propertyId,
          mappedPropertyId,
          verificationStatus: 'Approved',
        });
        enqueueSnackbar('Successfully verified mapping', { variant: 'success' });
        refetch();
      } catch (error) {
        enqueueSnackbar('Failed to verify mapping', { variant: 'error' });
      }
    },
    [showConfirmDialog, enqueueSnackbar, refetch],
  );

  const handleReject = useCallback(
    (propertyId: string, mappedPropertyId: string) => async () => {
      const confirmed = await showConfirmDialog({
        title: 'Reject Mapping?',
        description: `This will reject the mapping, are you sure you want to proceed?`,
      });
      if (!confirmed) {
        return;
      }
      try {
        await updateMapping({
          propertyId,
          mappedPropertyId,
          verificationStatus: 'Rejected',
        });
        enqueueSnackbar('Successfully rejected mapping', { variant: 'success' });
        refetch();
      } catch (error) {
        enqueueSnackbar('Failed to reject mapping', { variant: 'error' });
      }
    },
    [showConfirmDialog, enqueueSnackbar, refetch],
  );

  const columns: Array<GridColDef<MappedInternalProperty>> = [
    {
      field: 'name',
      headerName: 'Accommodation Property Name',
      display: 'flex',
      minWidth: 350,
      flex: 1,
      hideable: false,
      renderCell: (params) => (
        <Link
          href={`/accommodation/properties/${params.row.property.id}`}
          target="_blank"
          className="accommodation-properties-search-result-link"
        >
          {params.row.property.name}
        </Link>
      ),
    },
    {
      field: 'supplier name',
      headerName: 'Supplier Property Name',
      display: 'flex',
      minWidth: 350,
      hideable: true,
      renderCell: (params) => {
        return <Box>{params.row.internalProperty.name}</Box>;
      },
    },
    {
      field: 'source',
      headerName: 'Source',
      display: 'flex',
      minWidth: 100,
      hideable: true,
      renderCell: (params) => {
        return <Box>{params.row.internalProperty.source}</Box>;
      },
    },
    {
      field: 'sourceId',
      headerName: 'Source ID',
      display: 'flex',
      minWidth: 350,
      hideable: true,
      renderCell: (params) => {
        return (
          <InternalPropertyLink
            source={params.row.internalProperty.source}
            sourceId={params.row.internalProperty.sourceId}
          />
        );
      },
    },
    {
      field: 'supplierProperties',
      headerName: 'Supplier - ID',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const supplierProperties = params.row.internalProperty.supplierProperties || [];
        return (
          <Box>
            {supplierProperties.map((supplier, index) => (
              <Typography key={index} variant="body2">
                {supplier.supplier} - {supplier.supplierId}
              </Typography>
            ))}
          </Box>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Verify?',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1} justifyContent="center">
            <IconButton
              onClick={handleVerify(params.row.property.id, params.row.id)}
              color="success"
              size="small"
              title="Verify mapping"
            >
              <Check />
            </IconButton>
            <IconButton
              onClick={handleReject(params.row.property.id, params.row.id)}
              color="error"
              size="small"
              title="Reject mapping"
            >
              <Close />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      {fetchReqState.status === 'failed' && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {JSON.stringify(fetchReqState.error)}
        </Alert>
      )}
      <DataGrid
        className="potential-mappings-results-table"
        columns={columns}
        rows={fetchReqState.status === 'succeeded' ? fetchReqState.result.mappedProperties : []}
        rowCount={fetchReqState.status === 'succeeded' ? fetchReqState.result.total : undefined}
        getRowHeight={() => 70}
        pageSizeOptions={[PAGE_SIZE]}
        paginationMode="server"
        paginationModel={{ page: pageIndex, pageSize: PAGE_SIZE }}
        onPaginationModelChange={({ page }) => onPageChange(page)}
        loading={fetchReqState.status === 'pending'}
        autoHeight
        disableColumnFilter
        disableDensitySelector
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </>
  );
}
