import React, { useMemo, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import DeleteIcon from '@mui/icons-material/Delete';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Breakpoint,
  Button,
  ButtonGroup,
  Container,
  Grid,
  LinearProgress,
  Slider,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { blue, orange } from '@mui/material/colors';

import { generateWithBaseContent, generateWithMeta } from '~/services/AIContent';

interface Props {
  context: AIContext;
  content?: string;
  buttonLabel?: string;
  onChoose?: (result: string) => void;
  updateContext?: (component?: unknown) => AIContext;
  disableUseButton?: boolean;
  children?: React.ReactNode;
  sx?: SxProps;
  maxWidth?: false | Breakpoint;
  enableCustomPrompt?: boolean;
}

export interface AIContext {
  model: string;
  field: string;
  [key: string]: any;
}

export const AI_CREATIVITY = {
  Strict: { value: 1, label: 'Strict' },
  Standard: { value: 2, label: 'Standard' }, //default
  Creative: { value: 3, label: 'Creative' },
};

export default function AIGeneratorButton(props: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [returns, setReturns] = useState<string[]>([]);
  const [creativity, setCreativity] = useState<number>(2);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const [customPrompt, setCustomPrompt] = useState<string>('');

  const {
    context,
    content,
    buttonLabel,
    onChoose = () => true,
    updateContext = () => context,
    disableUseButton = false,
    children,
    sx,
    maxWidth,
    enableCustomPrompt = false,
  } = props;

  const sliderColor = useMemo(() => {
    switch (creativity) {
      case AI_CREATIVITY.Strict.value:
        return blue[300];
      case AI_CREATIVITY.Creative.value:
        return orange[700];
      case AI_CREATIVITY.Standard.value:
      default:
        return 'info';
    }
  }, [creativity]);

  const buttonBoxStyling = {
    paddingBottom: '1.1rem',
    marginBottom: '0.5rem',
  };

  const generate = async (withCustomPrompt = false) => {
    setIsFetching(true);

    const childrenContext = React.Children.toArray(props.children).reduce((acc, child) => {
      if (
        React.isValidElement(child) &&
        // eslint-disable-next-line no-prototype-builtins
        child.props.hasOwnProperty('contextKey') &&
        child.props.contextKey
      ) {
        acc[child.props.contextKey] = child.props.value;
      }
      return acc;
    }, {});

    const updatedContext = {
      ...context,
      ...updateContext(this),
      ...(childrenContext as object),
      ...(withCustomPrompt ? { customPrompt } : {}),
    };
    try {
      let res;
      if (content) {
        res = await generateWithBaseContent(content, updatedContext, creativity);
      } else {
        res = await generateWithMeta(updatedContext, creativity);
      }
      if (res.error) {
        console.error(res.error.message ?? res.error ?? 'Unknown error from AI service');
        throw new Error('Error contacting AI service, please try again.');
      }
      if (res.result === '') {
        throw new Error('No result, please try again.');
      }
      setReturns([...returns, res.result]);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setIsFetching(false);
    }
  };

  const chooseResult = (result: string, setValueAndClear = false) => {
    if (setValueAndClear) {
      onChoose && onChoose(result);
      setReturns([]);
      enqueueSnackbar('Applied selected generated content', {
        variant: 'success',
      });
    } else {
      navigator.clipboard.writeText(result);
      enqueueSnackbar('Copied to clipboard', { variant: 'success' });
    }
  };

  const resultsBox = useRef(null);

  const scrollToBottom = () => {
    resultsBox.current?.scrollToTop(resultsBox.current?.scrollHeight);
  };

  return (
    <Container sx={sx || { margin: '2.5rem 0', padding: '0 !important' }} maxWidth={maxWidth ?? 'xl'}>
      <Grid container spacing={1} justifyContent="space-between" sx={buttonBoxStyling}>
        <Grid item xs={3}>
          <Button variant="contained" onClick={() => generate()} startIcon={<PsychologyIcon />} disabled={isFetching}>
            {buttonLabel ?? 'AI Generate'}
          </Button>
        </Grid>
        {isExpanded ? (
          <>
            <Grid item xs={6}>
              <Slider
                aria-label="Creativity"
                defaultValue={2}
                step={1}
                marks={Object.values(AI_CREATIVITY)}
                min={1}
                max={3}
                value={creativity}
                sx={{
                  color: sliderColor,
                }}
                onChange={(_, value) => setCreativity(value as number)}
              />
            </Grid>
            <Grid item textAlign="right" xs={3}>
              <ButtonGroup variant="contained" aria-label="contained button group">
                <Button color="info" startIcon={<SettingsIcon />} onClick={() => setIsExpanded(false)}>
                  Hide Advanced
                </Button>
                <Button
                  color="error"
                  startIcon={<DeleteIcon />}
                  disabled={returns.length === 0}
                  onClick={() => setReturns([])}
                  title="Clear generated results"
                >
                  Clear
                </Button>
              </ButtonGroup>
            </Grid>
            {enableCustomPrompt && (
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <TextField
                    fullWidth
                    label="Custom Prompt"
                    value={customPrompt}
                    onChange={(e) => setCustomPrompt(e.target.value)}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Box>
                <Box mt={2}>
                  <Button variant="contained" onClick={() => generate(true)} disabled={isFetching}>
                    Generate with custom prompt
                  </Button>
                </Box>
              </Grid>
            )}
            <Grid item sx={{ marginBottom: '1rem' }} mt={2}>
              {children}
            </Grid>
          </>
        ) : (
          <Grid item textAlign="right" xs={3}>
            <ButtonGroup variant="outlined" aria-label="outlined button group" title="Show Advanced settings">
              <Button color="info" onClick={() => setIsExpanded(true)}>
                <SettingsIcon />
              </Button>
              <Button
                color="error"
                disabled={returns.length === 0}
                onClick={() => setReturns([])}
                title="Clear generated results"
              >
                <DeleteIcon />
              </Button>
            </ButtonGroup>
          </Grid>
        )}
      </Grid>
      {isFetching && <LinearProgress color="info" sx={{ margin: '0.5rem 0' }} />}
      <Box sx={{ overflowY: 'auto', maxHeight: '30vh' }} ref={resultsBox}>
        {returns.map((result, index) => (
          <Box
            key={index}
            sx={{
              padding: '1.1rem',
              borderBottom: 'solid 1px #eee',
              marginBottom: '0.5rem',
              ':hover': {
                backgroundColor: '#eee',
              },
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ padding: '1rem' }}>{result}</Typography>
            <ButtonGroup variant="contained" aria-label="contained button group">
              {!disableUseButton && (
                <Button
                  color="success"
                  variant="contained"
                  startIcon={<ContentPasteGoIcon />}
                  onClick={() => chooseResult(result, true)}
                >
                  Use
                </Button>
              )}
              <Button color="info" variant="contained" onClick={() => chooseResult(result, false)}>
                <ContentCopyIcon />
              </Button>
            </ButtonGroup>
          </Box>
        ))}
      </Box>
    </Container>
  );
}
