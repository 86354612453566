import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router';

import LoadingButton from '@mui/lab/LoadingButton';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import { Dayjs } from '~/services/TimeService';

import { EmptyArray } from '~/utils/arrayUtils';

import { DynamicSectionIdType, useDynamicSections } from '../context/useDynamicSections';

import DynamicSection, { ALLOWED_SECTIONS } from './DynamicSection';
import ScheduleDeletionButton from './ScheduleDeletionButton';
import ScheduleBannerForm, { parseScheduleBannerFormData } from './ScheduleForms/ScheduleBannerForm';
import ScheduleBaseForm, { parseScheduleBaseFormData } from './ScheduleForms/ScheduleBaseForm';
import ScheduleEmailContentForm, { parseScheduleEmailContentFormData } from './ScheduleForms/ScheduleEmailContentForm';
import ScheduleHeroOffersForm, { parseScheduleHeroOffersFormData } from './ScheduleForms/ScheduleHeroOffersForm';
import SchedulePushContentForm, {
  SCHEDULE_PUSH_TYPE,
  parseSchedulePushContentFormData,
} from './ScheduleForms/SchedulePushContentForm';
import ScheduleTestEmailModal from './ScheduleForms/ScheduleTestEmailModal';
import ScheduleTpfmForm, { parseScheduleTpfmFormData } from './ScheduleForms/ScheduleTpfmForm';
import SectionMenu from './SectionMenu';

function parseFormData(forms: {
  baseFormData: FormData;
  heroOffersFormData: FormData;
  emailContentFormData: FormData;
  pushContentFormData?: FormData;
  tpfmFormData: FormData;
  bannerFormData: FormData;
}): CustomerCommunication.UnsavedHeroPlannerSchedule {
  const { baseFormData, heroOffersFormData, emailContentFormData, pushContentFormData, tpfmFormData, bannerFormData } =
    forms;

  let unsaved: CustomerCommunication.UnsavedHeroPlannerSchedule = {
    ...parseScheduleBaseFormData(baseFormData),
    ...parseScheduleHeroOffersFormData(heroOffersFormData),
    ...parseScheduleEmailContentFormData(emailContentFormData),
    ...parseScheduleTpfmFormData(tpfmFormData),
    ...parseScheduleBannerFormData(bannerFormData),
  };

  if (pushContentFormData) {
    unsaved = { ...unsaved, ...parseSchedulePushContentFormData(pushContentFormData) };
  }

  return unsaved;
}

type Props = {
  busy?: boolean;
  onSubmit: (schedule: CustomerCommunication.UnsavedHeroPlannerSchedule) => void;
} & (
  | {
      editorType: 'new';
      schedule: CustomerCommunication.UnsavedHeroPlannerSchedule;
    }
  | {
      editorType: 'existing';
      schedule: CustomerCommunication.HeroPlannerSchedule;
    }
);

interface ParsedDynamicData {
  pushSchedules?: Array<ReturnType<typeof parseSchedulePushContentFormData>>;
}

function ScheduleEntityForm({ busy = false, editorType, schedule, onSubmit }: Props) {
  const history = useHistory();

  const [sendDate, setSendDate] = useState<Dayjs>(schedule.sendDate ?? '');
  const [brand, setBrand] = useState<string>(schedule.brandId ?? '');
  const [countryGroupId, setCountryGroupId] = useState<string>(schedule.countryGroupId ?? '');
  const [countryId, setCountryId] = useState<string>(schedule.countryId ?? '');
  const [offerIds, setOfferIds] = useState<Array<string>>(schedule.emailHeroOfferIds ?? EmptyArray);

  const baseFormRef = useRef<HTMLFormElement>(null);
  const heroOffersFormRef = useRef<HTMLFormElement>(null);
  const emailContentFormRef = useRef<HTMLFormElement>(null);
  const pushContentFormRef = useRef<HTMLFormElement>(null);
  const tpfmFormRef = useRef<HTMLFormElement>(null);
  const bannerFormRef = useRef<HTMLFormElement>(null);

  const {
    isToggled: isTestEmailModalOpen,
    toggleOn: openTestEmailModal,
    toggleOff: closeTestEmailModal,
  } = useToggleState(false);

  const {
    sections,
    deleteConfirmation,
    getSectionCount,
    addSection,
    handleDeleteClick,
    handleDeleteCancel,
    handleDeleteConfirm,
  } = useDynamicSections();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddSection = (sectionType: DynamicSectionIdType) => {
    addSection(sectionType);
    handleClose();
  };

  // Create a map of form refs for dynamic sections
  const dynamicFormRefs = useRef<Map<string, HTMLFormElement>>(new Map());

  // Initialize dynamic sections based on existing push schedules
  const initializeDynamicSections = useCallback(() => {
    if (schedule.pushSchedules?.length > 0) {
      schedule.pushSchedules.forEach((pushSchedule) => {
        addSection(pushSchedule.scheduleType);
      });
    }
  }, [schedule.pushSchedules, addSection]);

  // Call initialization on mount
  useEffect(() => {
    if (editorType === 'existing') {
      initializeDynamicSections();
    }
  }, [editorType, initializeDynamicSections]);

  const handleSubmissionClick = useCallback(() => {
    if (
      baseFormRef.current?.reportValidity() === false ||
      heroOffersFormRef.current?.reportValidity() === false ||
      emailContentFormRef.current?.reportValidity() === false ||
      pushContentFormRef.current?.reportValidity() === false ||
      bannerFormRef.current?.reportValidity() === false ||
      Array.from(dynamicFormRefs.current.values()).some((form) => form?.reportValidity() === false)
    ) {
      return;
    }

    const baseFormData = new FormData(baseFormRef.current);
    const heroOffersFormData = new FormData(heroOffersFormRef.current);
    const emailContentFormData = new FormData(emailContentFormRef.current);
    const pushContentFormData = pushContentFormRef.current ? new FormData(pushContentFormRef.current) : undefined;
    const tpfmFormData = new FormData(tpfmFormRef.current);
    const bannerFormData = new FormData(bannerFormRef.current);

    const initialData: ParsedDynamicData = {
      pushSchedules: [],
    };

    // Collect dynamic section form data
    const dynamicSectionData = sections
      .map((section) => {
        const formRef = dynamicFormRefs.current.get(section.id);
        if (!formRef) {
          return null;
        }

        const formData = new FormData(formRef);
        return {
          id: section.id,
          type: section.sectionId,
          position: section.position,
          formData,
        };
      })
      .filter(Boolean);

    // Parse dynamic section data based on type
    const parsedDynamicData = dynamicSectionData.reduce((acc, section) => {
      if (!section) {
        return acc;
      }

      switch (section.type) {
        case SCHEDULE_PUSH_TYPE.EVENING_PUSH:
          return {
            ...acc,
            pushSchedules: [...acc.pushSchedules, parseSchedulePushContentFormData(section.formData, section.type)],
          };
        default:
          return acc;
      }
    }, initialData);

    const unsaved = {
      ...parseFormData({
        baseFormData,
        heroOffersFormData,
        emailContentFormData,
        pushContentFormData,
        tpfmFormData,
        bannerFormData,
      }),
      ...parsedDynamicData,
    };

    onSubmit(unsaved);
  }, [onSubmit, sections]);

  const handleDeletion = useCallback(() => {
    history.push('/customer-communication/hero-planner/schedules');
  }, [history]);

  return (
    <>
      <Stack direction="column" gap={3}>
        <Paper sx={{ position: 'relative' }}>
          <ScheduleBaseForm
            ref={baseFormRef}
            sendDateValue={sendDate}
            brandValue={brand}
            countryGroupIdValue={countryGroupId}
            countryIdValue={countryId}
            stateIdDefaultValue={schedule.stateId}
            cityIdDefaultValue={schedule.cityId}
            segmentIdDefaultValue={schedule.segmentId}
            cadenceIdDefaultValue={schedule.cadenceId}
            membershipIdDefaultValue={schedule.membershipId}
            onSendDateChange={setSendDate}
            onBrandChange={setBrand}
            onCountryChange={setCountryId}
            onCountryGroupChange={setCountryGroupId}
          />
          <Backdrop open={busy} sx={{ position: 'absolute' }}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Paper>

        <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
          <Stack direction="column" gap={2}>
            <Typography variant="h4">Hero Offers</Typography>

            <ScheduleHeroOffersForm
              ref={heroOffersFormRef}
              sendDate={sendDate}
              brand={brand}
              countryGroupId={countryGroupId}
              countryId={countryId}
              offerIds={offerIds}
              onOfferIdsChange={setOfferIds}
            />
          </Stack>

          <Backdrop open={busy} sx={{ position: 'absolute' }}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Paper>

        <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
          <Stack direction="column" gap={2}>
            <Typography variant="h4">Top Picks For Me Email</Typography>
            <ScheduleTpfmForm ref={tpfmFormRef} isApprovedForTpfmInitial={schedule.isApprovedForTpfm} />
          </Stack>
        </Paper>

        <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
          <Stack direction="column" gap={2}>
            <Typography variant="h4">Banners</Typography>
            <ScheduleBannerForm
              ref={bannerFormRef}
              defaultEmailBannerInitial={schedule.emailBannerId}
              emailBannerPosition2Initial={schedule.emailBannerPosition2Id}
            />
          </Stack>
        </Paper>

        <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
          <Stack direction="column" gap={2}>
            <Typography variant="h4">Email Content</Typography>
            <ScheduleEmailContentForm
              ref={emailContentFormRef}
              preHeaderDefaultValue={schedule.emailPreHeader}
              subjectLineDefaultValue={schedule.emailSubjectLine}
              countryGroupId={countryGroupId}
              countryId={countryId}
              offerIds={offerIds}
            />
          </Stack>
          <Backdrop open={busy} sx={{ position: 'absolute' }}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Paper>

        {brand === 'luxuryescapes' && (
          <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
            <Stack direction="column" gap={2}>
              <Typography variant="h4">Push Content</Typography>
              <SchedulePushContentForm
                ref={pushContentFormRef}
                titleDefaultValue={schedule.pushTitle}
                subtitleDefaultValue={schedule.pushSubtitle}
                messageDefaultValue={schedule.pushMessage}
                linkDefaultValue={schedule.pushLink}
                imageIdDefaultValue={schedule.pushSvcImageId}
                countryGroupId={countryGroupId}
                countryId={countryId}
                offerId={offerIds[0]}
              />
            </Stack>
            <Backdrop open={busy} sx={{ position: 'absolute' }}>
              <CircularProgress color="primary" />
            </Backdrop>
          </Paper>
        )}

        {/* Dynamic Sections */}
        {sections.map((section) => {
          const sectionType = ALLOWED_SECTIONS.find((type) => type.id === section.sectionId);
          if (!sectionType) {
            return null;
          }

          return (
            <DynamicSection
              key={section.id}
              section={{ id: section.id, type: section.sectionId, position: section.position }}
              sectionType={sectionType}
              onDeleteClick={handleDeleteClick}
              busy={busy}
              formRef={(el) => {
                if (el) {
                  dynamicFormRefs.current.set(section.id, el);
                } else {
                  dynamicFormRefs.current.delete(section.id);
                }
              }}
              schedule={schedule}
            />
          );
        })}

        <SectionMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClick}
          onAddSection={handleAddSection}
          getSectionCount={getSectionCount}
        />

        <Stack direction="row-reverse" gap={2}>
          <LoadingButton loading={busy} variant="contained" size="large" onClick={handleSubmissionClick}>
            {editorType === 'new' && <span>Create new schedule</span>}
            {editorType === 'existing' && <span>Save schedule</span>}
          </LoadingButton>
          {editorType === 'existing' && (
            <Button type="button" variant="outlined" size="large" onClick={openTestEmailModal} disabled={busy}>
              Send test email
            </Button>
          )}
          {editorType === 'existing' && (
            <ScheduleDeletionButton scheduleId={schedule.id} disabled={busy} onDelete={handleDeletion} />
          )}
        </Stack>
      </Stack>
      {editorType === 'existing' && (
        <ScheduleTestEmailModal
          scheduleId={schedule.id}
          baseFormRef={baseFormRef}
          emailContentFormRef={emailContentFormRef}
          open={isTestEmailModalOpen}
          onClose={closeTestEmailModal}
        />
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmation.open} onClose={handleDeleteCancel} aria-labelledby="delete-dialog-title">
        <DialogTitle id="delete-dialog-title">Delete Section</DialogTitle>
        <DialogContent>Are you sure you want to delete this section? This action cannot be undone.</DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ScheduleEntityForm;
