import { useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import dealPageService, { DealPageSearchFormValues } from '~/services/cruises/DealPageService';

import { parseSearchString } from '~/utils/url';

import { CruiseDealPage } from '../pages/DealsPage/types';

interface Filter {
  name?: string;
  cruiseLine?: string;
  rateCodes?: string;
}

interface CruisesData {
  dealsPage: Array<CruiseDealPage>;
  page: number;
  total: number;
  isLoading: boolean;
  recordsPerPage: number;
  paginate: (page: number) => void;
  setFilter: (value: Filter) => void;
  filter: Filter;
  getDealsPage: (params?: DealPageSearchFormValues) => Promise<void>;
}

const RECORDS_PER_PAGE = 15;

export default function useCruiseDealsPage(): CruisesData {
  const [filter, setFilter] = useState<Filter>({});
  const { search } = useLocation();
  const { push: setQueryString } = useHistory();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [dealsPage, setDealsPage] = useState<Array<CruiseDealPage>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getDealsPage = useCallback(
    async (params?: DealPageSearchFormValues) => {
      try {
        const skip = (page - 1) * RECORDS_PER_PAGE;
        setIsLoading(true);
        const res = await dealPageService.listWithPagination({
          ...(params || {}),
          skip,
          take: RECORDS_PER_PAGE,
        });
        setDealsPage(res.result);
        setTotal(res.total);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [page],
  );

  const setCurrentPage = useCallback((): void => {
    const queryString = parseSearchString(search);
    if (queryString) {
      const pageToGo = parseInt(queryString.page as string);
      if (pageToGo) {
        setPage(pageToGo);
      }
    }
  }, [search]);

  const paginate = useCallback(
    (page: number): void => {
      const queryString = new URLSearchParams({
        page: page.toString(),
      }).toString();
      setQueryString({ search: queryString });
      setPage(page);
    },
    [setQueryString],
  );

  useEffect(() => {
    setCurrentPage();
  }, [setCurrentPage]);

  useEffect(() => {
    getDealsPage();
  }, [getDealsPage]);

  return {
    filter,
    setFilter,
    dealsPage,
    getDealsPage,
    page,
    recordsPerPage: RECORDS_PER_PAGE,
    paginate,
    total,
    isLoading,
  };
}
