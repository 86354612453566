import { useCallback, useState } from 'react';

import { SCHEDULE_PUSH_TYPE } from '../Components/ScheduleForms/SchedulePushContentForm';

export type DynamicSectionIdType = SCHEDULE_PUSH_TYPE;

export type DynamicSection = {
  // unique id for the section (It will be generated while adding the section)
  id: string;
  // matches id from SectionType
  sectionId: DynamicSectionIdType;
  // position in the list
  position: number;
};

export type DeleteConfirmation = {
  open: boolean;
  sectionId: string | null;
};

export const useDynamicSections = () => {
  const [sections, setSections] = useState<Array<DynamicSection>>([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState<DeleteConfirmation>({
    open: false,
    sectionId: null,
  });

  const getSectionCount = useCallback(
    (sectionType: string) => sections.filter((section) => section.sectionId === sectionType).length,
    [sections],
  );
  const addSection = useCallback((sectionType: DynamicSectionIdType) => {
    setSections((prev) => {
      const newSection = {
        sectionId: sectionType,
        id: `${sectionType}_${Date.now()}`,
        position: prev.length + 1,
      };

      const newSections = [...prev, newSection];

      // Sort sections to keep evening push at the bottom
      const sortedSections = newSections.sort((a, b) => {
        if (a.sectionId === SCHEDULE_PUSH_TYPE.EVENING_PUSH) {
          return 1;
        }
        if (b.sectionId === SCHEDULE_PUSH_TYPE.EVENING_PUSH) {
          return -1;
        }
        return a.position - b.position;
      });

      // Update positions after sorting
      return sortedSections.map((section, index) => ({
        ...section,
        position: index + 1,
      }));
    });
  }, []);

  const handleDeleteClick = useCallback((sectionId: string) => {
    setDeleteConfirmation({
      open: true,
      sectionId,
    });
  }, []);

  const handleDeleteCancel = useCallback(() => {
    setDeleteConfirmation({
      open: false,
      sectionId: null,
    });
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    if (deleteConfirmation.sectionId) {
      setSections((prev) => {
        const newSections = prev.filter((section) => section.id !== deleteConfirmation.sectionId);
        // Recompute positions after deletion
        return newSections.map((section, index) => ({
          ...section,
          position: index + 1,
        }));
      });
    }
    handleDeleteCancel();
  }, [deleteConfirmation.sectionId, handleDeleteCancel]);

  return {
    sections,
    deleteConfirmation,
    getSectionCount,
    addSection,
    handleDeleteClick,
    handleDeleteCancel,
    handleDeleteConfirm,
  };
};
