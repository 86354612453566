import { AccommodationPropertiesSearchInput } from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesSearchForm';

export function getSearchInputFromSearchParams(searchParams: URLSearchParams): AccommodationPropertiesSearchInput {
  const input: AccommodationPropertiesSearchInput = {};
  const propertyName = searchParams.get('propertyName');
  if (propertyName) {
    input.propertyName = propertyName;
  }
  const id = searchParams.get('id');
  if (id) {
    input.id = id;
  }

  return input;
}
