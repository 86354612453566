import React, { useCallback, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Alert, Box, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import AccommodationCreatePropertyModal from '~/components/Accommodation/Pages/Properties/components/AccommodationCreatePropertyModal';
import AccommodationMapPropertyFinder from '~/components/Accommodation/Pages/Properties/components/AccommodationMapPropertyFinder';
import MappingLogModal from '~/components/Accommodation/Pages/Properties/components/MappingLogModal';
import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';

import useToggleState from '~/hooks/useToggleState';

import { InternalProperty, createProperty, getUnmappedSuppliers } from '~/services/AccommodationService';

import { AccommodationPropertiesSearchInput } from './AccommodationPropertiesSearchForm';

interface Props {
  searchInput: AccommodationPropertiesSearchInput;
  pageIndex?: number;
  onPageChange: (pageIndex: number) => void;
}

const PAGE_SIZE = 20;

export default function AccommodationUnmappedSuppliersResultsTable({ searchInput, pageIndex, onPageChange }: Props) {
  const [internalProperty, setInternalProperty] = useState<InternalProperty>(null);
  const [logsId, setLogsId] = useState<string | null>(null);

  const {
    isToggled: isMapPropertyModalOpen,
    toggleOn: setOpenMapPropertyModal,
    toggleOff: setCloseMapPropertyModal,
  } = useToggleState(false);

  const {
    isToggled: isMappingLogModalOpen,
    toggleOn: setOpenMappingLogModal,
    toggleOff: setCloseMappingLogModal,
  } = useToggleState(false);

  const {
    isToggled: isCreatePropertyModalOpen,
    toggleOn: setOpenCreatePropertyModal,
    toggleOff: setCloseCreatePropertyModal,
  } = useToggleState(false);

  const getUnmappedSupplierFetch = useCallback(
    () =>
      getUnmappedSuppliers({
        name: searchInput.propertyName,
        id: searchInput.id,
        page: String((pageIndex ?? 0) + 1),
        limit: String(PAGE_SIZE),
      }),
    [pageIndex, searchInput],
  );

  const { fetchReqState, refetch } = useAccommodationServiceFetch({
    fetchFn: getUnmappedSupplierFetch,
  });

  const onMap = useCallback(
    async (internalProperty: InternalProperty) => {
      setInternalProperty(internalProperty);
      setOpenMapPropertyModal();
    },
    [setOpenMapPropertyModal],
  );

  const onAdd = useCallback(
    async (internalProperty: InternalProperty) => {
      setInternalProperty(internalProperty);
      setOpenCreatePropertyModal();
    },
    [setOpenCreatePropertyModal],
  );

  const showMappingLogs = useCallback(
    async (logsId: string) => {
      setLogsId(logsId);
      setOpenMappingLogModal();
    },
    [setOpenMappingLogModal],
  );

  const columns: Array<GridColDef<InternalProperty>> = [
    {
      field: 'name',
      headerName: 'Property Name',
      display: 'flex',
      minWidth: 350,
      flex: 1,
      hideable: false,
    },
    {
      field: 'source',
      headerName: 'Source',
      display: 'flex',
      minWidth: 100,
      hideable: true,
    },
    {
      field: 'sourceId',
      headerName: 'Source ID',
      display: 'flex',
      minWidth: 350,
      hideable: true,
    },
    {
      field: 'supplierProperties',
      headerName: 'Supplier - ID',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const supplierProperties = params.row.supplierProperties || [];
        return (
          <Box>
            {supplierProperties.map((supplier, index) => (
              <Typography key={index} variant="body2">
                {supplier.supplier} - {supplier.supplierId}
              </Typography>
            ))}
          </Box>
        );
      },
    },
    {
      field: ' ',
      headerName: 'Actions',
      headerAlign: 'center',
      width: 150,
      sortable: false,
      align: 'center',
      display: 'flex',
      renderCell: (params) => {
        return (
          <Box display="flex" gap={1}>
            <IconButton onClick={() => showMappingLogs(params.row.id)} color="info">
              <Tooltip title="Show mapping logs">
                <ListAltIcon fontSize="medium" />
              </Tooltip>
            </IconButton>
            <IconButton onClick={() => onAdd(params.row)} color="info">
              <Tooltip title="Create New Property">
                <AddIcon fontSize="medium" />
              </Tooltip>
            </IconButton>
            <IconButton onClick={() => onMap(params.row)} color="info">
              <Tooltip title="Map To Existing Property">
                <LinkIcon fontSize="medium" />
              </Tooltip>
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {fetchReqState.status === 'failed' && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {JSON.stringify(fetchReqState.error)}
        </Alert>
      )}
      <DataGrid
        className="accommodation-unmapped-suppliers-search-results-table"
        columns={columns}
        rows={fetchReqState.status === 'succeeded' ? fetchReqState.result.internalProperties : []}
        rowCount={fetchReqState.status === 'succeeded' ? fetchReqState.result.total : undefined}
        getRowId={(row) => row.sourceId}
        getRowHeight={() => 'auto'}
        pageSizeOptions={[PAGE_SIZE]}
        paginationMode="server"
        paginationModel={{ page: pageIndex, pageSize: PAGE_SIZE }}
        onPaginationModelChange={({ page }) => onPageChange(page)}
        loading={fetchReqState.status === 'pending'}
        autoHeight
        disableColumnFilter
        disableDensitySelector
        slots={{
          toolbar: GridToolbar,
        }}
      />
      <Dialog open={isMapPropertyModalOpen} onClose={() => setCloseMapPropertyModal()} fullWidth maxWidth="md">
        {isMapPropertyModalOpen && (
          <>
            <DialogTitle>Select a property to map to "{internalProperty.name}"</DialogTitle>
            <DialogContent>
              <AccommodationMapPropertyFinder
                internalProperty={internalProperty}
                onClose={setCloseMapPropertyModal}
                refetchUnmapped={refetch}
              />
            </DialogContent>
          </>
        )}
      </Dialog>
      <AccommodationCreatePropertyModal
        open={isCreatePropertyModalOpen}
        onClose={setCloseCreatePropertyModal}
        apiCall={(props) => createProperty({ ...props, internalPropertyId: internalProperty.id })}
      />
      {isMappingLogModalOpen && (
        <MappingLogModal
          isMappingLogModalOpen={isMappingLogModalOpen}
          setCloseMappingLogModal={setCloseMappingLogModal}
          logsSourceId={logsId}
          type="property"
        />
      )}
    </>
  );
}
