import React, { useEffect, useState } from 'react';

import { Alert, Box, Tooltip } from '@mui/material';

import { API } from '@luxuryescapes/lib-types';

import { getPayments } from '~/services/PaymentsService';

interface Props {
  order: App.Order;
}

function SingleOrderPayments({ order }: Props) {
  const [payments, setPayments] = useState<Array<API.Payment.Payment>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await getPayments(order.id_orders);
        setPayments(response.result as Array<API.Payment.Payment>);
      } catch (err) {
        setError('Failed to load payments');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPayments();
  }, [order.id]);

  if (isLoading) {
    return <div>Loading payments...</div>;
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {error}
      </Alert>
    );
  }

  return (
    <Box>
      <strong>Payments</strong>
      {payments.length === 0 ? (
        <div>No payments found</div>
      ) : (
        <div>
          {payments.map((payment, index) => (
            <div key={index}>
              {payment.type === 'stripe' ? (
                <a
                  href={`https://dashboard.stripe.com/payments/${(payment.response_json as any)?.data?.object?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {payment.type}
                </a>
              ) : (
                payment.type
              )}{' '}
              {payment.intent} {parseFloat(payment.amount).toLocaleString('en-US', { minimumFractionDigits: 0 })}{' '}
              {payment.status}
              {payment.type == 'credit' && parseFloat(payment.amount) > 0 && (
                <Tooltip title="Fraudsters want to spend credit, not their own money. The higher percentage of credit, the higher risk of fraud.">
                  <span>🟠</span>
                </Tooltip>
              )}
            </div>
          ))}
        </div>
      )}
    </Box>
  );
}

export { SingleOrderPayments };
