import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import { CruiseDealPage } from '~/components/Cruises/pages/DealsPage/types';

import cruisesAPI from './CruisesApiService';

const RESOURCE = 'deals';

export type DealPageSearchFormValues = API.PaginationParams & {
  name?: string;
  cruiseLineId?: string;
  rateCodes?: Array<string>;
};

const listWithPagination = async (
  paginationParams: DealPageSearchFormValues,
): Promise<API.Response<Array<CruiseDealPage>>> => {
  // TODO: Remove this mock
  // https://aussiecommerce.atlassian.net/browse/CRUZ-2778
  return Promise.resolve({
    result: [
      {
        id: '1',
        name: 'Deal 1',
        slug: 'deal-1',
        headline: 'Deal 1',
        subHeadline: 'Deal 1',
        termsConditions: 'Deal 1',
        heroImageId: '1',
        cruiseLine: {
          id: '1',
          name: 'Cruise Line 1',
        },
        startDate: '2025-01-01',
        endDate: '2025-01-01',
        rates: [],
        minimumCabinRestrictions: 1,
        fulfilmentInstructions: 'Deal 1',
      },
    ],
    status: 200,
    total: 1,
  });
  // return await cruisesAPI.http.get({ resource: RESOURCE, paginationParams }, undefined, VERSION);
};

const deleteById = async (id: string) => {
  await cruisesAPI.http.delete({
    resource: `${RESOURCE}/${id}`,
  });
};

export default {
  listWithPagination,
  deleteById,
};
