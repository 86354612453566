import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Button, Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import useCruiseDealsPage from '~/components/Cruises/context/useCruiseDealsPage';

import { formatDateShort } from '~/services/TimeService';
import dealPageService from '~/services/cruises/DealPageService';

import { CruiseDealPage } from '../types';

import DealPageSearchForm from './DealPageSearchForm';

export default function DealPageList() {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteButton, setDeleteButton] = useState(null);
  const { dealsPage, page, total, recordsPerPage, paginate, getDealsPage, isLoading } = useCruiseDealsPage();
  const history = useHistory();

  const handleDeleteDeal = useCallback(
    async (id: string) => {
      try {
        await dealPageService.deleteById(id);
        enqueueSnackbar('Deal Page deleted', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error deleting Deal Page', { variant: 'error' });
      }
    },
    [enqueueSnackbar],
  );

  const actionColumn = useCallback(
    (cell, row) => (
      <Box>
        {deleteButton !== row.id && (
          <Box>
            <Button
              variant="text"
              size="small"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                history.push(`/cruises/deals/edit/${row.id}`);
              }}
            >
              Edit
            </Button>

            <Button
              variant="text"
              size="small"
              color="error"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDeleteButton(row.id);
              }}
            >
              Delete
            </Button>
          </Box>
        )}

        {deleteButton === row.id && (
          <Box>
            <Button
              variant="text"
              size="small"
              color="error"
              onClick={async (event) => {
                event.preventDefault();
                event.stopPropagation();
                await handleDeleteDeal(row.id);
                getDealsPage();
              }}
            >
              Confirm
            </Button>

            <Button
              variant="text"
              size="small"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDeleteButton(null);
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    ),
    [deleteButton, handleDeleteDeal, getDealsPage, history],
  );

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Deal Page Name',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      display: 'flex',
    },
    {
      field: 'cruiseLine',
      headerName: 'Cruise Line',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => params.value.name,
      display: 'flex',
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => formatDateShort(params.value),
      display: 'flex',
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => formatDateShort(params.value),
      display: 'flex',
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 180,
      sortable: false,
      renderCell: (params) => actionColumn(params.value, params.row),
      display: 'flex',
    },
  ];

  return (
    <Box mt={1}>
      <DealPageSearchForm isLoading={isLoading} onSearch={getDealsPage} />

      <Stack mt={3} width="100%" direction="row" justifyContent="flex-end">
        <Button component={Link} to="/cruises/deals/create" variant="text" size="small">
          Add New Deal Page
        </Button>
      </Stack>

      <DataGrid
        loading={isLoading}
        rows={dealsPage || []}
        columns={columns}
        pagination
        paginationModel={{ page: page - 1, pageSize: recordsPerPage }}
        pageSizeOptions={[10]}
        getRowId={(row: CruiseDealPage) => row.id}
        paginationMode="server"
        rowCount={total}
        onPaginationModelChange={({ page }) => paginate(page + 1)}
        slots={{ pagination: GridPagination }}
        autoHeight
      />
    </Box>
  );
}
