import React, { ComponentProps, forwardRef, useCallback, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router';

import { Stack } from '@mui/material';

import { AccommodationTabs } from '~/components/Accommodation/Pages/Home/AccommodationHomePage';
import AccommodationPropertiesResultsTable, {
  AccommodationPropertiesResultsTableRef,
} from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesResultsTable';
import AccommodationPropertiesSearchForm from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesSearchForm';
import usePagedSearch from '~/components/Accommodation/hooks/usePagedSearch';

interface Props {
  selectedTab: AccommodationTabs;
  refreshProperties: () => void;
}

const AccommodationPropertiesResultsContainer = forwardRef<AccommodationPropertiesResultsTableRef, Props>(
  function AccommodationProperties({ refreshProperties }, ref) {
    const history = useHistory();
    const location = useLocation();

    const handleSearchFormSubmission = useCallback<
      ComponentProps<typeof AccommodationPropertiesSearchForm>['onSubmit']
    >(
      (formObject) => {
        const urlSearchParamsToPush = new URLSearchParams(formObject);
        history.push({
          search: urlSearchParamsToPush.toString(),
        });
      },
      [history],
    );

    const urlSearchParams = useMemo<URLSearchParams>(() => new URLSearchParams(location.search), [location.search]);

    const { searchInput, pageNumber } = usePagedSearch(urlSearchParams);

    const handlePageChange = useCallback(
      (pageIndex: number) => {
        const urlSearchParamsToPush = new URLSearchParams(urlSearchParams);
        if (pageIndex) {
          urlSearchParamsToPush.set('page', String(pageIndex + 1));
        } else {
          urlSearchParamsToPush.delete('page');
        }
        history.push({
          search: urlSearchParamsToPush.toString(),
        });
      },
      [urlSearchParams, history],
    );

    return (
      <Stack direction="column" spacing={2}>
        <AccommodationPropertiesSearchForm
          initialInput={searchInput}
          onSubmit={handleSearchFormSubmission}
          refreshProperties={refreshProperties}
          selectedTab={AccommodationTabs.PROPERTIES}
        />
        <AccommodationPropertiesResultsTable
          ref={ref}
          searchInput={searchInput}
          pageIndex={pageNumber - 1}
          onPageChange={handlePageChange}
        />
      </Stack>
    );
  },
);

export default AccommodationPropertiesResultsContainer;
