import React, { MouseEventHandler, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import requestGetHeroPlannerSchedulePushContent from '~/queries/customerCommunication/requestGetHeroPlannerSchedulePushContent';

import { Alert, AlertTitle, Box, Button, Stack, TextField } from '@mui/material';

import { RequestStatus } from '~/consts/requestConstants';

import { isRequestRejected } from '~/utils/requestUtils';

import SchedulePushImageInput from './Inputs/SchedulePushImageInput';

export enum SCHEDULE_PUSH_CONTENT_INPUT_NAMES {
  TITLE = 'schedule_push_content_title_input_name',
  SUBTITLE = 'schedule_push_content_subtitle_input_name',
  MESSAGE = 'schedule_push_content_message_input_name',
  IMAGE_ID = 'schedule_push_content_image_id_input_name',
  LINK = 'schedule_push_content_link_input_name',
}

export enum SCHEDULE_PUSH_TYPE {
  // TODO: Current Daily Push Types need to be considered as DEFAULT
  DEFAULT = 'DEFAULT',
  EVENING_PUSH = 'EVENING',
}

export function parseSchedulePushContentFormData(
  formData: FormData,
  scheduleType: SCHEDULE_PUSH_TYPE | undefined = undefined,
): Pick<
  CustomerCommunication.UnsavedHeroPlannerSchedule,
  'pushTitle' | 'pushSubtitle' | 'pushMessage' | 'pushLink' | 'pushSvcImageId' | 'scheduleType'
> {
  const pushTitle = String(formData.get(SCHEDULE_PUSH_CONTENT_INPUT_NAMES.TITLE));
  const pushSubtitle = String(formData.get(SCHEDULE_PUSH_CONTENT_INPUT_NAMES.SUBTITLE));
  const pushMessage = String(formData.get(SCHEDULE_PUSH_CONTENT_INPUT_NAMES.MESSAGE));
  const pushLink = String(formData.get(SCHEDULE_PUSH_CONTENT_INPUT_NAMES.LINK));
  const pushSvcImageId = String(formData.get(SCHEDULE_PUSH_CONTENT_INPUT_NAMES.IMAGE_ID));

  return {
    pushTitle: !isEmpty(pushTitle) ? pushTitle : undefined,
    pushSubtitle: !isEmpty(pushSubtitle) ? pushSubtitle : undefined,
    pushMessage: !isEmpty(pushMessage) ? pushMessage : undefined,
    pushLink: !isEmpty(pushLink) ? pushLink : undefined,
    pushSvcImageId: !isEmpty(pushSvcImageId) ? pushSvcImageId : undefined,
    scheduleType: !isEmpty(scheduleType) ? scheduleType : undefined,
  };
}

interface Props {
  titleDefaultValue: string;
  subtitleDefaultValue: string;
  messageDefaultValue: string;
  linkDefaultValue: string;
  imageIdDefaultValue: string;
  countryGroupId?: string;
  countryId?: string;
  offerId?: string;
  showPrefillButton?: boolean;
}

const SchedulePushContentForm = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const {
    titleDefaultValue,
    subtitleDefaultValue,
    messageDefaultValue,
    linkDefaultValue,
    imageIdDefaultValue,
    countryGroupId,
    countryId,
    offerId,
    showPrefillButton = true,
  } = props;

  const brand = useSelector((state: App.State) => state.tenant.brand);

  const titleInputRef = useRef<HTMLInputElement>(null);
  const subtitleInputRef = useRef<HTMLInputElement>(null);
  const messageInputRef = useRef<HTMLInputElement>(null);
  const imageIdInputRef = useRef<HTMLInputElement>(null);
  const linkInputRef = useRef<HTMLInputElement>(null);

  const [emailContentRequest, setEmailContentRequest] = useState<
    Utils.RequestState<CustomerCommunication.HeroPlannerSchedulePushContent, string>
  >({
    status: RequestStatus.INITIAL,
  });

  const params = useMemo(() => {
    if (brand && countryGroupId && countryId && offerId) return [brand, countryGroupId, countryId, offerId] as const;
  }, [brand, countryGroupId, countryId, offerId]);

  const handlePrefillClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    async (event) => {
      event.preventDefault();
      setEmailContentRequest({
        status: RequestStatus.PENDING,
        params,
      });

      try {
        const result = await requestGetHeroPlannerSchedulePushContent(...params);
        setEmailContentRequest({
          status: RequestStatus.FULFILLED,
          params,
          result,
        });
      } catch (error) {
        setEmailContentRequest({
          status: RequestStatus.REJECTED,
          params,
          error,
        });
      }
    },
    [params],
  );

  useEffect(() => {
    if (emailContentRequest.result?.title) {
      titleInputRef.current.value = emailContentRequest.result.title;
    }
    if (emailContentRequest.result?.subtitle) {
      subtitleInputRef.current.value = emailContentRequest.result.subtitle;
    }
    if (emailContentRequest.result?.message) {
      messageInputRef.current.value = emailContentRequest.result.message;
    }
    if (emailContentRequest.result?.imageId) {
      imageIdInputRef.current.value = emailContentRequest.result.imageId;
    }
    if (emailContentRequest.result?.link) {
      linkInputRef.current.value = emailContentRequest.result.link;
    }
  }, [emailContentRequest.result]);

  return (
    <Stack component="form" ref={ref} direction="column" gap={2}>
      {showPrefillButton && (
        <Box>
          <Button variant="outlined" color="secondary" disabled={!params} onClick={handlePrefillClick}>
            Prefill push content
          </Button>
          {isRequestRejected(emailContentRequest) && (
            <Alert severity="error" sx={{ mt: 1 }}>
              <AlertTitle>Problem with prefilling!</AlertTitle>
              <pre>{emailContentRequest.error}</pre>
            </Alert>
          )}
        </Box>
      )}
      <TextField
        inputRef={titleInputRef}
        name={SCHEDULE_PUSH_CONTENT_INPUT_NAMES.TITLE}
        defaultValue={titleDefaultValue}
        label="Title"
        variant="standard"
        multiline
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        inputRef={subtitleInputRef}
        name={SCHEDULE_PUSH_CONTENT_INPUT_NAMES.SUBTITLE}
        defaultValue={subtitleDefaultValue}
        label="Subtitle"
        variant="standard"
        multiline
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        inputRef={messageInputRef}
        name={SCHEDULE_PUSH_CONTENT_INPUT_NAMES.MESSAGE}
        defaultValue={messageDefaultValue}
        label="Message"
        variant="standard"
        multiline
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        inputRef={linkInputRef}
        name={SCHEDULE_PUSH_CONTENT_INPUT_NAMES.LINK}
        defaultValue={linkDefaultValue}
        label="Link"
        variant="standard"
        InputLabelProps={{ shrink: true }}
      />
      <SchedulePushImageInput
        ref={imageIdInputRef}
        name={SCHEDULE_PUSH_CONTENT_INPUT_NAMES.IMAGE_ID}
        defaultValue={imageIdDefaultValue}
      />
    </Stack>
  );
});

SchedulePushContentForm.displayName = 'SchedulePushForm';
export default SchedulePushContentForm;
