import React from 'react';

import { Helmet } from 'react-helmet';

import { Box, Stack } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import DealPageList from './components/DealPageList';

export default function DealPageManagement() {
  return (
    <Stack direction="column" spacing={2}>
      <Box>
        <Helmet>
          <title>Deal Page</title>
        </Helmet>

        <PageHeader title="Deal Page" />

        <Box mt={1}>
          <DealPageList />
        </Box>
      </Box>
    </Stack>
  );
}
