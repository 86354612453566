import React from 'react';

import { Box, Button, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ALLOWED_SECTIONS } from './DynamicSection';

type SectionMenuProps = {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onAddSection: (sectionType: string) => void;
  getSectionCount: (sectionType: string) => number;
};

const StyledMenuItem = styled(MenuItem)(({ disabled }) => ({
  opacity: disabled ? 0.5 : 1,
  '&.Mui-disabled': {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
}));

function SectionMenu({
  anchorEl,
  open,
  onClose,
  onClick,
  onAddSection,
  getSectionCount,
}: SectionMenuProps): JSX.Element {
  return (
    <Stack direction="row" justifyContent="left">
      <Button variant="contained" onClick={onClick} startIcon={<span>+</span>}>
        Create New Section
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        {ALLOWED_SECTIONS.map((sectionType) => {
          const currentCount = getSectionCount(sectionType.id);
          const isDisabled = currentCount >= sectionType.limit;

          return (
            <Tooltip
              key={sectionType.id}
              title={isDisabled ? `Maximum limit (${sectionType.limit}) reached` : ''}
              placement="right"
            >
              <Box>
                <StyledMenuItem onClick={() => !isDisabled && onAddSection(sectionType.id)} disabled={isDisabled}>
                  {sectionType.label}
                </StyledMenuItem>
              </Box>
            </Tooltip>
          );
        })}
      </Menu>
    </Stack>
  );
}

export default SectionMenu;
