import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { Backdrop, CircularProgress, IconButton, Paper, Stack, Typography } from '@mui/material';

import { DynamicSectionIdType } from '../context/useDynamicSections';

import ScheduleBannerForm from './ScheduleForms/ScheduleBannerForm';
import SchedulePushContentForm, { SCHEDULE_PUSH_TYPE } from './ScheduleForms/SchedulePushContentForm';

export type SectionComponentType = typeof ScheduleBannerForm | typeof SchedulePushContentForm;
export type SectionType = {
  id: DynamicSectionIdType;
  label: string;
  limit: number;
  component: SectionComponentType;
};

export const ALLOWED_SECTIONS: Array<SectionType> = [
  {
    id: SCHEDULE_PUSH_TYPE.EVENING_PUSH,
    label: 'Push Notification - Evening',
    limit: 1,
    component: SchedulePushContentForm,
  },
  // More section types will be added here soon.... (Offer, Banner, Push, Destination, etc)
];

interface Props {
  section: { id: string; type: string; position: number };
  sectionType: SectionType;
  onDeleteClick: (id: string) => void;
  busy?: boolean;
  formRef: (element: HTMLFormElement | null) => void;
  schedule: CustomerCommunication.HeroPlannerSchedule | CustomerCommunication.UnsavedHeroPlannerSchedule;
}

function DynamicSection({ section, sectionType, onDeleteClick, busy = false, formRef, schedule }: Props): JSX.Element {
  const Component = sectionType.component;

  const renderComponent = () => {
    if (Component === SchedulePushContentForm && schedule) {
      // Find matching push schedule based on type
      const matchingPushSchedule = schedule.pushSchedules?.find(
        (pushSchedule) => pushSchedule.scheduleType === section.type,
      );

      return (
        <SchedulePushContentForm
          ref={formRef}
          titleDefaultValue={matchingPushSchedule?.pushTitle ?? ''}
          subtitleDefaultValue={matchingPushSchedule?.pushSubtitle ?? ''}
          messageDefaultValue={matchingPushSchedule?.pushMessage ?? ''}
          linkDefaultValue={matchingPushSchedule?.pushLink ?? ''}
          imageIdDefaultValue={matchingPushSchedule?.pushSvcImageId ?? ''}
          countryGroupId={schedule.countryGroupId}
          countryId={schedule.countryId}
          offerId={schedule.emailHeroOfferIds?.[0]}
          showPrefillButton={false}
        />
      );
    }

    return null;
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        p: 2,
        position: 'relative',
        mb: 2,
      }}
    >
      <Stack direction="column" gap={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">{sectionType.label}</Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              onClick={() => onDeleteClick(section.id)}
              size="small"
              aria-label="delete section"
              sx={{
                '&:hover': {
                  color: 'error.main',
                },
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Stack>
        {renderComponent()}
      </Stack>
      <Backdrop open={busy} sx={{ position: 'absolute' }}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Paper>
  );
}

export default DynamicSection;
