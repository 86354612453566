import React, { useCallback, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import qs from 'qs';
import { Helmet } from 'react-helmet';

import { Chip, Grid, LinearProgress, Link, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import { GHAPriceCheckSearch } from '~/components/GHAPriceCheck/GHAPriceCheckSearch';
import { Suppliers, calculateItineraryDetails } from '~/components/GHAPriceCheck/uills';

import { DEFAULT_PAGE_SIZES } from '~/consts/filters';

import * as connectionGoogleService from '~/services/ConnectionGoogleService';
import { GHAPriceCheckRow, GHAPriceSummary } from '~/services/ConnectionGoogleService';

const CP_HOST = window.configs['LUX_CUSTOMER_PORTAL'];

const sharedAttrs: Pick<GridColDef, 'sortable' | 'display' | 'flex'> = {
  sortable: false,
  display: 'flex',
  flex: 1,
};

const columns: Array<GridColDef> = [
  {
    field: 'propertyId',
    headerName: 'Property ID',
    ...sharedAttrs,
    flex: 2,
  },
  {
    field: 'checkIn',
    headerName: 'Check In',
    ...sharedAttrs,
  },
  {
    field: 'checkOut',
    headerName: 'Check Out',
    ...sharedAttrs,
  },
  {
    field: 'PublicOffer',
    headerName: 'Public Offer Rates',
    renderCell: (params) => {
      const supplierCode = params.row.publicOffer?.supplierCode;
      return (
        <>
          {params.row.publicOffer ? (
            <Stack>
              <Typography>
                <b>Price:</b> {params.row.publicOffer?.price ?? '-'}
              </Typography>
              <Typography>
                <b>Supplier:</b> {supplierCode ? Suppliers[supplierCode] : '-'}
              </Typography>
              <Typography>
                <b>Room ID:</b> {params.row.publicOffer?.roomId ?? `-`}
              </Typography>
            </Stack>
          ) : (
            'Offer Unavailable'
          )}
        </>
      );
    },
    ...sharedAttrs,
    flex: 2,
  },
  {
    field: 'metasearch',
    headerName: 'Metasearch Rates',
    renderCell: (params) => {
      const supplierCode = params.row.publicOffer?.supplierCode;
      return (
        <>
          {params.row.metaSearch ? (
            <Stack>
              <Typography>
                <b>Price:</b> {params.row.metaSearch?.price ?? '-'}
              </Typography>
              <Typography>
                <b>Supplier:</b> {supplierCode ? Suppliers[supplierCode] : '-'}
              </Typography>
              <Typography>
                <b>Room ID:</b> {params.row.metaSearch?.roomId ?? `-`}
              </Typography>
            </Stack>
          ) : (
            'Offer Unavailable'
          )}
        </>
      );
    },
    ...sharedAttrs,
    flex: 2,
  },
  {
    field: 'priceMatch',
    headerName: 'Price Match',
    renderCell: (params) => {
      return (
        <>
          {params.row.mismatch?.isMismatch ? (
            <Chip label="No" color={'error'} />
          ) : (
            <Chip label="Yes" color={'success'} />
          )}{' '}
        </>
      );
    },
    ...sharedAttrs,
  },
  {
    field: 'mismatchReason',
    headerName: 'Reason',
    renderCell: (params) => {
      return params.row.mismatch?.isMismatch && params.row.mismatch?.reason
        ? `${params.row.mismatch.difference} AUD difference. (${params.row.mismatch?.reason})`
        : '';
    },
    ...sharedAttrs,
  },
  {
    field: 'viewOffer',
    headerName: 'View Offer',
    renderCell: (params) => {
      const { checkIn, checkOut, propertyId, occupancy, publicOffer } = params.row;
      const roomTypeId = publicOffer?.roomId;

      const queryParams = {
        source: 'metasearch',
        utm_bp: 2,
        checkIn,
        checkOut,
        propertyId: `le:${propertyId}`,
        roomTypeId,
        adults: occupancy,
        children: '',
      };

      return (
        <Link
          color="primary"
          target="_blank"
          rel="noreferrer"
          href={`${CP_HOST}/google-hotel-ads?${qs.stringify(queryParams)}`}
          underline="hover"
        >
          View Offer
        </Link>
      );
    },
    ...sharedAttrs,
  },
];

const DEFAULT_OCCUPANCY = 2;

export function GHAPriceCheckContainer() {
  const { enqueueSnackbar } = useSnackbar();

  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');
  const [propertyIds, setPropertyIds] = useState<string>('');
  const [checkIn, setCheckIn] = useState<string | undefined>();
  const [nights, setNights] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [combinations, setCombinations] = useState<number>(1);
  const [occupancy, setOccupancy] = useState<number>(DEFAULT_OCCUPANCY);

  const [dataRows, setDataRows] = useState<GHAPriceCheckRow[]>([]);
  const [summary, setSummary] = useState<GHAPriceSummary>(null);

  const { isKnownItinerary = false, calculatedCombinations } = useMemo(
    () => calculateItineraryDetails(propertyIds, checkIn, nights, combinations),
    [checkIn, nights, propertyIds, combinations],
  );

  const fetchData = useCallback(async () => {
    setFetchingState('success');
    setCombinations(calculatedCombinations);
    try {
      setFetchingState('loading');
      const { result } = await connectionGoogleService.ghaPriceDiagnoseQuery({
        propertyIds,
        checkIn: checkIn?.length > 0 ? checkIn : undefined,
        nights,
        isMobile,
        combinations: calculatedCombinations > 50 ? 50 : calculatedCombinations,
        occupancy: occupancy || DEFAULT_OCCUPANCY,
      });

      if (!result) {
        setFetchingState('failed');
        setDataRows([]);
        setSummary(null);
        enqueueSnackbar('Error while conducting price checks', { variant: 'error' });
        return;
      }
      setDataRows(result.results.map((row: any, index: number) => ({ id: index + 1, ...row })));
      setSummary(result.summary);
      setFetchingState('success');
    } catch (e) {
      setFetchingState('failed');
      setDataRows([]);
      setSummary(null);
      enqueueSnackbar(`Error while conducting price check: ${e.message}`, { variant: 'error' });
    }
  }, [propertyIds, checkIn, nights, isMobile, calculatedCombinations, enqueueSnackbar]);

  return (
    <>
      <Helmet>
        <title>Search Google Hotel Ad Prices</title>
      </Helmet>
      <GHAPriceCheckSearch
        propertyIds={propertyIds}
        nights={nights}
        isMobile={isMobile}
        checkIn={checkIn}
        combinations={combinations}
        isKnownItinerary={isKnownItinerary}
        occupancy={occupancy}
        setCheckIn={setCheckIn}
        setNights={setNights}
        setPropertyIds={setPropertyIds}
        setIsMobile={setIsMobile}
        setCombinations={setCombinations}
        setOccupancy={setOccupancy}
        onSubmit={fetchData}
      />

      {fetchingState === 'success' && (
        <Grid container spacing={3} mb={4} mt={2}>
          <Grid item sm={12}>
            <h3>Summary</h3>
            <LinearProgress
              variant="determinate"
              color={summary.mismatchPercentage > 20 ? 'error' : 'success'}
              value={100 - summary.mismatchPercentage}
              valueBuffer={100}
            />
            <h4>
              {summary.mismatchCount} mismatches found out of {summary.totalItineraries} itineraries
            </h4>
            <h4>Mismatch percentage {summary.mismatchPercentage} %</h4>
          </Grid>
        </Grid>
      )}

      <DataGrid
        loading={fetchingState === 'loading'}
        rows={dataRows}
        columns={columns}
        disableRowSelectionOnClick
        autoHeight
        pagination
        paginationMode="client"
        pageSizeOptions={DEFAULT_PAGE_SIZES}
        slots={{ pagination: GridPagination }}
        getRowHeight={() => 'auto'}
      />
    </>
  );
}
