import React, { MouseEvent } from 'react';

import { Box, Button, Link, Stack } from '@mui/material';

import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';
import PageHeader from '~/components/Common/Elements/PageHeader';

import { formatDateWithClock } from '~/services/TimeService';

interface OverviewPaneProps {
  property: App.Bedbank.PropertyEdit;
  syncRates: boolean;
  fetchScanRatesData: (event: MouseEvent) => void;
  postSyncRatesData: (event: MouseEvent) => void;
  onUpdateSellPricesClick: (event: MouseEvent) => void;
  onRoomMappingClick: (event: MouseEvent) => void;
  onRoomGenerationClick: (event: MouseEvent) => void;
  onAvailabilityScoreClick: (event: MouseEvent) => void;
  onSyncContentClick: (event: MouseEvent) => void;
}

export default function OverviewPane(props: OverviewPaneProps) {
  const { pricesSyncAt } = props.property;

  const hasPromotions = props.property.promotions.length > 0;

  return (
    <div>
      <PageHeader title="Property overview">
        <Stack direction="row" spacing={1} alignItems="center">
          <Button variant="text" size="small" onClick={props.fetchScanRatesData}>
            Rates
          </Button>

          {hasPromotions && (
            <>
              <Button variant="text" size="small" onClick={props.postSyncRatesData} disabled={props.syncRates}>
                Sync Prices
              </Button>

              <Button
                variant="text"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  const lePath = `${window.configs['LUX_CUSTOMER_PORTAL']}/partner/${props.property.slug}/${props.property.id}?preview=true&previewHash=${props.property.previewHash}`;
                  window.open(lePath, '_blank');
                }}
                disabled={props.syncRates}
              >
                Preview
              </Button>
            </>
          )}

          <Button variant="text" size="small" onClick={props.onUpdateSellPricesClick}>
            Update Sell Prices
          </Button>

          <Button variant="text" size="small" onClick={props.onRoomMappingClick}>
            Map Rooms
          </Button>

          <Button variant="text" size="small" onClick={props.onRoomGenerationClick}>
            Generate Rooms
          </Button>

          <Button variant="text" size="small" onClick={props.onAvailabilityScoreClick}>
            Availability Score
          </Button>

          <Button variant="text" size="small" onClick={props.onSyncContentClick}>
            Sync Content
          </Button>
        </Stack>
      </PageHeader>

      <Box display="grid" gap={2} gridTemplateColumns="repeat(5, 1fr)">
        <Box>
          <DefinitionTitle>Name</DefinitionTitle>
          <DefinitionText>{props.property.name}</DefinitionText>
        </Box>

        <Box>
          <DefinitionTitle>Country</DefinitionTitle>
          <DefinitionText>{props.property.country}</DefinitionText>
        </Box>

        <Box>
          <DefinitionTitle>State / Province</DefinitionTitle>
          <DefinitionText>{props.property.stateProvinceName ?? '-'}</DefinitionText>
        </Box>

        <Box>
          <DefinitionTitle>City</DefinitionTitle>
          <DefinitionText>{props.property.city ?? '-'}</DefinitionText>
        </Box>

        <Box>
          <DefinitionTitle>Preview</DefinitionTitle>
          <DefinitionText>
            <Stack direction="row" spacing={1}>
              <Link
                href={`${window.configs['LUX_CUSTOMER_PORTAL']}/partner/${props.property.slug}/${props.property.id}?preview=true&previewHash=${props.property.previewHash}`}
              >
                LE
              </Link>
              <Link href={`${window.configs['EXPEDIA_PORTAL']}/${props.property.externalSlug}`}>EPS</Link>
            </Stack>
          </DefinitionText>
        </Box>
      </Box>

      <Box mt={4} display="grid" gap={2} gridTemplateColumns="repeat(8, 1fr)">
        <Box gridColumn="span 2">
          <DefinitionTitle>ID</DefinitionTitle>
          <DefinitionText>{props.property.id}</DefinitionText>
        </Box>

        <Box>
          <DefinitionTitle>Rating</DefinitionTitle>
          <DefinitionText>{props.property.rating}</DefinitionText>
        </Box>

        <Box>
          <DefinitionTitle>Chain ID</DefinitionTitle>
          <DefinitionText>{props.property.chainId ?? '-'}</DefinitionText>
        </Box>

        <Box>
          <DefinitionTitle>Chain name</DefinitionTitle>
          <DefinitionText>{props.property.chainName ?? '-'}</DefinitionText>
        </Box>

        {hasPromotions && (
          <Box gridColumn="span 2">
            <DefinitionTitle>Cache prices last updated</DefinitionTitle>
            <DefinitionText>{pricesSyncAt ? formatDateWithClock(pricesSyncAt) : '-'}</DefinitionText>
          </Box>
        )}
      </Box>

      <Box mt={4} display="grid" gap={2} gridTemplateColumns="repeat(8, 1fr)">
        <Box>
          <DefinitionTitle>EXPEDIA ID</DefinitionTitle>
          <DefinitionText>{props.property.expediaId ?? '-'}</DefinitionText>
        </Box>

        <Box>
          <DefinitionTitle>HOTELBEDS ID</DefinitionTitle>
          <DefinitionText>{props.property.hotelbedsId ?? '-'}</DefinitionText>
        </Box>

        <Box>
          <DefinitionTitle>WEBBEDS ID</DefinitionTitle>
          <DefinitionText>{props.property.webbedsId ?? '-'}</DefinitionText>
        </Box>

        <Box>
          <DefinitionTitle>STUBA ID</DefinitionTitle>
          <DefinitionText>{props.property.stubaId ?? '-'}</DefinitionText>
        </Box>

        <Box>
          <DefinitionTitle>DIDA ID</DefinitionTitle>
          <DefinitionText>{props.property.didaId ?? '-'}</DefinitionText>
        </Box>

        <Box>
          <DefinitionTitle>NUITEE ID</DefinitionTitle>
          <DefinitionText>{props.property.nuiteeId ?? '-'}</DefinitionText>
        </Box>
      </Box>
    </div>
  );
}

export { OverviewPane };
