import React, { useCallback, useState } from 'react';

import { Box, Button, Stack, TextField } from '@mui/material';

import { DealPageSearchFormValues } from '~/services/cruises/DealPageService';

const INITIAL_SEARCH_STATE = {
  name: '',
  cruiseLineId: undefined,
  rateCodes: [],
};

type Props = {
  isLoading: boolean;
  onSearch: (searchValues: DealPageSearchFormValues) => void;
};

function DealPageSearchForm({ isLoading, onSearch }: Props) {
  const [searchValues, setSearchValues] = useState<DealPageSearchFormValues>(INITIAL_SEARCH_STATE);

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      if (event?.target && event?.target?.name) {
        setSearchValues({
          ...searchValues,
          [event.target.name]: event.target.value,
        });
      }
    },
    [searchValues],
  );

  const handleSearch = useCallback(() => {
    onSearch({
      name: searchValues.name || undefined,
      cruiseLineId: searchValues.cruiseLineId || undefined,
      rateCodes: searchValues.rateCodes || undefined,
    });
  }, [searchValues, onSearch]);

  return (
    <Stack direction="column" spacing={2} border={1} borderColor="divider" borderRadius={1} p={2}>
      <Stack direction="row" spacing={2}>
        <Box flexGrow={2}>
          <TextField fullWidth name="name" label="Deal Name" onChange={handleChangeField} value={searchValues.name} />
        </Box>
        <Box flexGrow={2}>
          <TextField
            fullWidth
            name="cruiseLineId"
            label="Cruise Line ID"
            onChange={handleChangeField}
            value={searchValues.cruiseLineId}
            placeholder="Use comma to search multiple cruise lines"
          />
        </Box>
      </Stack>
      <Box flexGrow={1}>
        <TextField
          fullWidth
          name="rateCodes"
          label="Rate Codes"
          onChange={handleChangeField}
          value={searchValues.rateCodes}
        />
      </Box>

      <Box>
        <Button variant="contained" disabled={isLoading} onClick={handleSearch}>
          Search Content
        </Button>
      </Box>
    </Stack>
  );
}

export default DealPageSearchForm;
