import React from 'react';

import { Helmet } from 'react-helmet';
import { NavLink, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Authenticated, NotAuthenticated } from '~/stormpath';

import { Box, Button, Card, Container, Toolbar } from '@mui/material';

import AccountDeletionsPage from '~/components/AccountDeletions/pages/Home/AccountDeletionsPage';
import BusinessMainPage from '~/components/LeBusinessTraveller/pages/MainPage';
import MetricsResultsContainer from '~/components/Metrics/MetricsResultsContainer';
import VendorInclusionsPage from '~/components/Vendors/Profile/VendorInclusionsPage';
import RoomTypesGroupsPageContainer from '~/components/Vendors/Properties/RoomTypesGroupsPageContainer';

import CustomOffersCreatePage from '~/pages/CustomOffers/Create';
import CustomOffersEditPage from '~/pages/CustomOffers/Edit';

import { ITEM_TYPE_GIFT_CARD, ITEM_TYPE_INSURANCE, ITEM_TYPE_OFFER, ITEM_TYPE_OFFLINE_FLIGHT } from '~/consts/order';

import useCurrentTenant from '~/hooks/useCurrentTenant';

import AccommodationHomePage from './Accommodation/Pages/Home/AccommodationHomePage';
import AccommodationPropertyDetailsPage from './Accommodation/Pages/PropertyDetails/AccommodationPropertyDetailsPage';
import AmenitiesPageContainer from './Amenities/AmenitiesPageContainer';
import AmenityEdit from './Amenities/AmenityEdit';
import AmenityGroupEdit from './Amenities/AmenityGroupEdit';
import BedbankPropertiesEditPage from './Bedbank/Properties/Edit/Page';
import BedbankPropertiesPage from './Bedbank/Properties/Home/Page';
import { BookingPage } from './Bookings/BookingPage';
import Footer from './Common/Footer';
import { SiteHeader } from './Common/SiteHeader';
import CurationPageContainer from './Content/CurationPageContainer';
import CruiseLineEdit from './Cruises/pages/CruiseLines/CruiseLineEdit';
import CruiseLineOrdering from './Cruises/pages/CruiseLines/CruiseLineOrdering/CruiseLineOrdering';
import CruisePageImages from './Cruises/pages/Home/CruisePageImages';
import CruisesPage from './Cruises/pages/Home/CruisesPage';
import CruisePageDetails from './Cruises/pages/Home/CruisesPageDetails';
import CruisesBookingLogsPage from './Cruises/pages/Home/components/Bookings/BookingLogsPage';
import CruisesBookingsPage from './Cruises/pages/Home/components/Bookings/BookingsPage';
import CruiseCabinCategoriesDetailsPage from './Cruises/pages/Home/components/CabinCategories/CabinCategoriesDetails';
import CabinCategoriesGroupOverviewPage from './Cruises/pages/Home/components/CabinCategoriesGroup/CabinCategoriesGroupOverview/CabinCategoriesGroupOverviewPage';
import CreateCabinCategoriesGroupPage from './Cruises/pages/Home/components/CabinCategoriesGroup/CreateCabinCategoriesGroup/CreateCabinCategoriesGroup';
import CruiseSailingDetailsPage from './Cruises/pages/Home/components/Sailings/SailingDetailsPage';
import CruiseInclusionsCreate from './Cruises/pages/Inclusions/InclusionsCreate';
import CruiseInclusionsEdit from './Cruises/pages/Inclusions/InclusionsEdit/InclusionsEdit';
import CruisePortsEditPage from './Cruises/pages/Ports/PortsEditPage';
import CruisePromoBannerCreate from './Cruises/pages/PromoBanner/PromoBannerCreate';
import CruisePromoBannerEdit from './Cruises/pages/PromoBanner/PromoBannerEdit';
import CruisePromoBannerOrdering from './Cruises/pages/PromoBanner/PromoBannerOrdering/PromoBannerOrdering';
import CreatePromotionPage from './Cruises/pages/Promotions/CreatePromotionPage';
import EditPromotionPage from './Cruises/pages/Promotions/EditPromotionPage';
import PromotionManagementPage from './Cruises/pages/Promotions/PromotionManagementPage';
import EditRateCodePage from './Cruises/pages/RateCodeManagement/EditRateCodePage';
import RateCodeManagementPage from './Cruises/pages/RateCodeManagement/RateCodeManagementPage';
import ShipImageEditor from './Cruises/pages/Ships/ShipImageEditor';
import HeroPlannerScheduleEditorPage from './CustomerCommunication/HeroPlanner/Pages/HeroPlannerScheduleEditorPage';
import HeroPlannerSchedulesListPage from './CustomerCommunication/HeroPlanner/Pages/HeroPlannerSchedulesListPage';
import EmailPage from './Emails/EmailPage';
import EmailPageContainer from './Emails/EmailPageContainer';
import RenderEmail from './Emails/RenderEmail';
import ExperiencesPage from './Experiences';
import FinancePageContainer from './Finance/FinancePageContainer';
import FinanceOfferDetailContainer from './Finance/OfferDetail/OfferDetail';
import VendorBookingPaymentsContainer from './Finance/OfferEdit/VendorBookingPaymentsContainer';
import VendorOfferPaymentsContainer from './Finance/OfferEdit/VendorOfferPaymentsContainer';
import CreditReservationDetailContainer from './Flights/CreditReservationDetailContainer';
import CreditReservationEditContainer from './Flights/CreditReservationEditContainer';
import CreditReservationListContainer from './Flights/CreditReservationListContainer';
import FlightDealsEdit from './Flights/FlightDealsEdit';
import FlightsPage from './Flights/FlightsPageContainer';
import HealthPage from './Health/HealthPage';
import IndexPage from './IndexPage';
import LereAdminPageContainer from './LERE/LereAdminPageContainer';
import BusinessTabContainer from './LeBusinessTraveller/pages/BusinessDetailsPage/TabContainer';
import BadgesContainer from './Marketing/Badges/BadgesContainer';
import BadgesDetailContainer from './Marketing/Badges/BadgesDetailContainer';
import CompedPackagesDetailContainer from './Marketing/CompedPackages/CompedPackagesDetailContainer';
import MarketingPage from './Marketing/MarketingPage';
import OfferDetailContainer from './Offers/Detail/OfferDetailContainer';
import BadgesEditContainer from './Offers/Edit/BadgesEditContainer';
import BundlePackagesEditContainer from './Offers/Edit/BundlePackagesEditContainer';
import ExperiencesEditContainer from './Offers/Edit/ExperiencesEditContainer';
import ImagesEditContainer from './Offers/Edit/ImagesEditContainer';
import LuxMomentsPage from './Offers/Edit/LuxMomentsPage';
import NewScheduleContainer from './Offers/Edit/NewScheduleContainer';
import OfferEditContainer from './Offers/Edit/OfferEditContainer';
import PackagesEditContainer from './Offers/Edit/PackagesEditContainer';
import RoomPolicyEditContainer from './Offers/Edit/RoomPoliciesEditContainer';
import ScheduleEditContainer from './Offers/Edit/ScheduleEditContainer';
import OfferSearchOrderingContainer from './Offers/Edit/SearchOrderingContainer';
import TravellerDetailRequirementsEditPage from './Offers/Edit/TravellerDetailRequirements';
import VideosEditContainer from './Offers/Edit/VideosEditContainer';
import OffersPage from './Offers/Home/OffersPage';
import LoginPage from './Profile/LoginPage';
import ProfilePage from './Profile/ProfilePage';
import PromoDetailContainerV3 from './PromoV3/PromoDetailContainerV3';
import OrderEditContainer from './Purchases/Edit/OrderEditContainer';
import GiftCardDetailPage from './Purchases/GiftCardDetail/PageContainer';
import PurchasesPageContainer from './Purchases/Home/PurchasesPageContainer';
import OrderAddNotePage from './Purchases/OrderDetail/OrderAddNotePage';
import OrderAtolPageContainer from './Purchases/OrderDetail/OrderAtolPageContainer';
import OrderBookingContainer from './Purchases/OrderDetail/OrderBookingPageContainer';
import OrderDetailContainer from './Purchases/OrderDetail/OrderDetailPageContainer';
import OrderGiftEditPage from './Purchases/OrderDetail/OrderGiftEditPage';
import OrderPaymentSurcharge from './Purchases/OrderDetail/OrderPaymentSurcharge';
import SupportAssistantPage from './SupportAssistant/pages/Home/SupportAssistantPage';
import AdminUsersPageContainer from './System/AdminUsersPageContainer';
import AuditLogSearch from './System/AuditLogSearch';
import ImageUploaderUtilityPage from './System/ImageUploader/ImageUploaderUtilityPage';
import PermissionsContainer from './System/PermissionsContainer';
import ReservationRemediation from './System/ReservationRememdiation/ReservationRemediation';
import SalesFxHedging from './System/SalesFxHedging/SalesFxHedgingPage';
import Settings from './System/Settings/Settings';
import SpeedNavigation from './System/SpeedNavigation';
import SystemPageContainer from './System/SystemPageContainer';
import SystemUserPage from './System/SystemUserPage';
import UpdateMonitor from './System/UpdateMonitor';
import ToursLEListPage from './Tours/Pages/Lists/ToursLEListPage';
import ToursTTCListPage from './Tours/Pages/Lists/ToursTTCListPage';
import TourDetailsPage from './Tours/Pages/TourDetailsPage';
import TripDetailPage from './TripPlanner/TripDetailPage';
import TripPlannerPage from './TripPlanner/TripPlannerPage';
import CreditsContainer from './Users/Credits/CreditsContainer';
import UserHistory from './Users/History/UserHistory';
import LoyaltyContainer from './Users/Loyalty/Container';
import AddItemPageContainer from './Users/Orders/AddItem/AddItemPageContainer';
import AtolPage from './Users/Orders/Atol/AtolPage';
import NewOrderPageContainer from './Users/Orders/NewOrder/NewOrderPageContainer';
import UpdateInsuranceContainer from './Users/Orders/UpdateInsurance';
import UserPage from './Users/UserPage';
import UsersPageContainer from './Users/UsersPageContainer';
import { VendorAddonDetails } from './Vendors/Addons/AddonDetails';
import { VendorAddonEdit } from './Vendors/Addons/AddonEdit';
import EditChainSettingsPage from './Vendors/ChainSettings/EditChainSettingsPage';
import VendorsPageContainer from './Vendors/Home/VendorsPageContainer';
import VendorsPartnerCentralPage from './Vendors/PartnerCentral/VendorsPartnerCentralPage';
import ChainsDetailContainer from './Vendors/Profile/ChainsDetailContainer';
import VendorsDetailContainer from './Vendors/Profile/VendorsDetailContainer';
import CuratedContentEditPage from './Vendors/Properties/CuratedContentEditPage';
import ImagesEditPage from './Vendors/Properties/ImagesEditPage';
import PropertyCommissionableTaxesContentEditPage from './Vendors/Properties/PropertyCommissionableTaxesContentEditPage';
import PropertyEditContainer from './Vendors/Properties/PropertyEditContainer';
import PropertyNewContainer from './Vendors/Properties/PropertyNewContainer';
import PropertyPageContainer from './Vendors/Properties/PropertyPageContainer';
import PropertyReviewsEditContainer from './Vendors/Properties/PropertyReviewsEditContainer';
import PropertyTaxesContentEditPage from './Vendors/Properties/PropertyTaxesContentEditPage';
import PropertyTaxesFeesEditPage from './Vendors/Properties/PropertyTaxesFeesEditPage';
import RoomTypeDatesEditContainer from './Vendors/Properties/RoomTypeDatesEditContainer';
import RoomTypeEditContainer from './Vendors/Properties/RoomTypeEditContainer';
import RoomTypesEditContainer from './Vendors/Properties/RoomTypesEditContainer';
import VideosEditPage from './Vendors/Properties/VideosEditPage';
import RatePlanEditContainer from './Vendors/RatePlans/RatePlanEditContainer';
import RatePlanNewContainer from './Vendors/RatePlans/RatePlanNewContainer';
import RatePlanPageContainer from './Vendors/RatePlans/RatePlanPageContainer';
import RatePlansGroupEditContainer from './Vendors/RatePlansGroups/RatePlansGroupEditContainer';
import RoomTypesGroupEditContainer from './Vendors/RoomTypesGroups/RoomTypesGroupEditContainer';
import TourEditContainer from './Vendors/Tours/TourEditContainer';
import TourLegsEditContainer from './Vendors/Tours/TourLegsEditContainer';
import TourNewContainer from './Vendors/Tours/TourNewContainer';
import TourOptionDatesEditContainer from './Vendors/Tours/TourOptionDatesEditContainer';
import TourOptionsEditContainer from './Vendors/Tours/TourOptionsEditContainer';
import TourPageContainer from './Vendors/Tours/TourPageContainer';
import TourReviewsEditContainer from './Vendors/Tours/TourReviewsEditContainer';

const TOURS_SUB_NAVIGATIONS: Array<App.NavigationLink> = [
  {
    href: '/tours/lists/le',
    label: 'LE Tours',
  },
  {
    href: '/tours/lists/ttc',
    label: 'TTC Tours',
  },
];

export default function MasterPage() {
  const { tenant } = useCurrentTenant();
  const location = useLocation();

  const { pathname } = location;

  return (
    <Box className="MasterPage" sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Helmet titleTemplate="%s" defaultTitle={`Admin Portal | ${tenant.title}`} />

      <SiteHeader tenant={tenant} />

      <UpdateMonitor />

      <Box flexGrow={1} mt={2}>
        <Authenticated>
          <SpeedNavigation />
          <Switch>
            <Route exact path="/" component={IndexPage} />
            <Redirect exact from="/curation" to="/curation/offer-list-order" />
            <Route exact path="/curation/:tab" component={CurationPageContainer} />
            <Route exact path="/experiences" component={ExperiencesPage} />
            <Route exact path="/experience/:id_experience" component={ExperiencesPage} />
            <Route exact path="/offers/:id_offer" component={OfferDetailContainer} />
            <Route exact path="/offers" component={OffersPage} />
            <Route exact path="/trip-planner" component={TripPlannerPage} />
            <Route exact path="/trip-planner/trip/:tripId" component={TripDetailPage} />

            <Route exact path="/edit-offers/:id_offer" component={OfferEditContainer} />

            <Route
              exact
              path="/edit-offers/:vertical/:id_offer/search-orderings"
              component={OfferSearchOrderingContainer}
            />

            <Route exact path="/edit-offers/:id_offer/schedules/:brand" component={ScheduleEditContainer} />

            <Route exact path="/edit-offers/:id_offer/schedules" component={NewScheduleContainer} />
            <Route exact path="/edit-offers/:id_offer/bundle-packages" component={BundlePackagesEditContainer} />
            <Route exact path="/edit-offers/:id_offer/packages" component={PackagesEditContainer} />
            <Route exact path="/edit-offers/:id_offer/images" component={ImagesEditContainer} />
            <Route exact path="/edit-offers/:id_offer/videos" component={VideosEditContainer} />
            <Route exact path="/edit-offers/:id_offer/lux-moments" component={LuxMomentsPage} />

            <Route exact path="/edit-offers/:id_offer/room-policies" component={RoomPolicyEditContainer} />

            <Route exact path="/edit-offers/:id_offer/badges" component={BadgesEditContainer} />

            <Route
              exact
              path="/edit-offers/:id_offer/traveller-detail-requirements"
              component={TravellerDetailRequirementsEditPage}
            />

            <Route exact path="/edit-offers/:id_offer/experiences" component={ExperiencesEditContainer} />

            <Route path="/tours">
              <Container sx={{ mb: 2 }}>
                <Card variant="outlined">
                  <Toolbar>
                    {TOURS_SUB_NAVIGATIONS.map((nav) => (
                      <Button
                        key={nav.href}
                        component={NavLink}
                        to={nav.href}
                        color={pathname === nav.href ? 'primary' : 'secondary'}
                        size="large"
                      >
                        {nav.label}
                      </Button>
                    ))}
                  </Toolbar>
                </Card>
              </Container>

              <Switch>
                <Redirect exact from="/tours" to="tours/lists/le" />

                <Route exact path="/tours/lists/le" component={ToursLEListPage} />

                <Route exact path="/tours/lists/ttc" component={ToursTTCListPage} />

                <Route path="/tours/details/:tour_id" component={TourDetailsPage} />
                {/* Backwards compatibility for the Chrome Extension */}
                <Redirect from="/tours/:tour_id" to="/tours/details/:tour_id" />
              </Switch>
            </Route>

            <Route exact path="/bedbank/properties" component={BedbankPropertiesPage} />
            <Route exact path="/bedbank/properties/:id" component={BedbankPropertiesEditPage} />
            <Route exact path="/flights" component={FlightsPage} />
            <Route exact path="/flights/credit-reservation" component={CreditReservationListContainer} />
            <Route exact path="/flights/credit-reservation/:id/details" component={CreditReservationDetailContainer} />
            <Route exact path="/flights/credit-reservation/:id/edit" component={CreditReservationEditContainer} />
            <Route exact path="/flights/flight-deal/:id/edit" component={FlightDealsEdit} />

            <Route exact path="/users-list/:tab?/:id?" component={UsersPageContainer} />

            <Route exact path="/users/:id_user/custom-offers/create" component={CustomOffersCreatePage} />
            <Route exact path="/users/:id_user/custom-offers/:offer_id" component={CustomOffersEditPage} />

            <Route exact path="/users/:id_user/credits" component={CreditsContainer} />
            <Route exact path="/users/:id_user/loyalty/:code" component={LoyaltyContainer} />
            <Route exact path="/users/:user_email" component={UserPage} />
            <Route exact path="/users/:id_user/history" component={UserHistory} />
            <Route
              exact
              path="/users/:id_user/new-order/:step?"
              render={(props) => <NewOrderPageContainer orderType={ITEM_TYPE_OFFER} {...props} />}
            />
            <Route
              exact
              path="/users/:id_user/new-flight-order/:step?"
              render={(props) => <NewOrderPageContainer orderType={ITEM_TYPE_OFFLINE_FLIGHT} {...props} />}
            />
            <Route
              exact
              path="/users/:id_user/new-gift-card-order/:step?"
              render={(props) => <NewOrderPageContainer orderType={ITEM_TYPE_GIFT_CARD} {...props} />}
            />
            <Route
              exact
              path="/users/:id_user/new-insurance-order/:step?"
              render={(props) => <NewOrderPageContainer orderType={ITEM_TYPE_INSURANCE} {...props} />}
            />
            <Route
              exact
              path="/users/:id_user/edit-orders/:id_orders/add-item/:step?"
              component={AddItemPageContainer}
            />
            <Route
              path="/users/:id_user/edit-orders/:id_order/insurance/:id/:step"
              component={UpdateInsuranceContainer}
              exact
            />
            <Route exact path="/users/:id_user/atol" component={AtolPage} />

            <Route exact path="/purchases" component={PurchasesPageContainer} />
            <Route exact path="/purchases/section/:section" component={PurchasesPageContainer} />

            <Route exact path="/purchases/:id_orders/gift-card" component={GiftCardDetailPage} />
            <Route exact path="/purchases/:id_orders/payment" component={OrderPaymentSurcharge} />
            <Route exact path="/purchases/:id_orders/gift/edit" component={OrderGiftEditPage} />
            <Route exact path="/purchases/:id_orders/notes/add" component={OrderAddNotePage} />
            <Route path="/purchases/:id_orders/booking-details" component={OrderBookingContainer} />
            <Route path="/purchases/:id_orders/atol-details" component={OrderAtolPageContainer} />
            <Route path="/purchases/:id_orders" component={OrderDetailContainer} />
            <Route exact path="/edit-purchases/:id_orders" component={OrderEditContainer} />
            <Route exact path="/system/users/:id_user" component={SystemUserPage} />
            <Route exact path="/system" component={SystemPageContainer} />
            <Route exact path="/system/account-deletions" component={AccountDeletionsPage} />
            <Route exact path="/system/admin-users" component={AdminUsersPageContainer} />
            <Route exact path="/system/reservation-remediation" component={ReservationRemediation} />
            <Route exact path="/system/audit-logs" component={AuditLogSearch} />
            <Route exact path="/system/permissions/:role" component={PermissionsContainer} />
            <Route exact path="/system/settings" component={Settings} />
            <Route exact path="/system/image-uploader" component={ImageUploaderUtilityPage} />
            <Route exact path="/system/sales-fx-hedging" component={SalesFxHedging} />

            <Route exact path="/vendors" component={VendorsPageContainer} />
            <Route exact path="/vendors/:id_vendor" component={VendorsDetailContainer} />
            <Route exact path="/vendors/:id_vendor/partner-central" component={VendorsPartnerCentralPage} />
            <Route exact path="/vendors/chain/:id_parent" component={ChainsDetailContainer} />
            <Route exact path="/vendors/chain/:id_parent/settings" component={EditChainSettingsPage} />
            <Route exact path="/vendors/:id_vendor/properties" component={PropertyNewContainer} />
            <Route exact path="/vendors/:id_vendor/properties/:id_property" component={PropertyPageContainer} />
            <Route exact path="/vendors/:id_vendor/edit-property/:id_property" component={PropertyEditContainer} />
            <Route exact path="/vendors/:id_vendor/inclusions" component={VendorInclusionsPage} />
            <Route exact path="/vendors/:id_vendor/rate-plans-groups" component={RatePlansGroupEditContainer} />
            <Route exact path="/vendors/:id_vendor/rate-plans" component={RatePlanNewContainer} />
            <Route exact path="/vendors/:id_vendor/rate-plans/:id_rate_plan" component={RatePlanPageContainer} />
            <Route exact path="/vendors/:id_vendor/edit-rate-plan/:id_rate_plan" component={RatePlanEditContainer} />
            <Route
              exact
              path="/vendors/:id_vendor/properties/:id_property/edit-curated-content"
              component={CuratedContentEditPage}
            />
            <Route
              exact
              path="/vendors/:id_vendor/properties/:id_property/edit-room-types"
              component={RoomTypesEditContainer}
            />
            <Route exact path="/vendors/:id_vendor/properties/:id_property/edit-images" component={ImagesEditPage} />
            <Route exact path="/vendors/:id_vendor/properties/:id_property/edit-videos" component={VideosEditPage} />
            <Route
              exact
              path="/vendors/:id_vendor/properties/:id_property/room-types/:id_room_type/room-rates/:id_room_rate"
              component={RoomTypeEditContainer}
            />
            <Route
              exact
              path="/vendors/:id_vendor/properties/:id_property/room-types/:id_room_type/room-rates/:id_room_rate/dates"
              component={RoomTypeDatesEditContainer}
            />
            <Route
              exact
              path="/vendors/:id_vendor/properties/:id_property/room-types-groups"
              component={RoomTypesGroupsPageContainer}
            />
            <Route
              exact
              path="/vendors/:id_vendor/properties/:id_property/edit-room-types-groups"
              component={RoomTypesGroupEditContainer}
            />
            <Route
              exact
              path="/vendors/:id_vendor/properties/:id_property/edit-property-reviews"
              component={PropertyReviewsEditContainer}
            />
            <Route
              exact
              path="/vendors/:id_vendor/properties/:id_property/edit-property-taxes-content"
              component={PropertyTaxesContentEditPage}
            />
            <Route
              exact
              path="/vendors/:id_vendor/properties/:id_property/edit-property-commissionable-taxes-content"
              component={PropertyCommissionableTaxesContentEditPage}
            />
            <Route
              exact
              path="/vendors/:id_vendor/properties/:id_property/edit-property-taxes-fees"
              component={PropertyTaxesFeesEditPage}
            />
            <Route exact path="/vendors/:id_vendor/tours/:id_tour" component={TourPageContainer} />
            <Route exact path="/vendors/:id_vendor/edit-tour/:id_tour" component={TourEditContainer} />
            <Route exact path="/vendors/:id_vendor/tours" component={TourNewContainer} />
            <Route
              exact
              path="/vendors/:id_vendor/tours/:id_tour/edit-tour-options"
              component={TourOptionsEditContainer}
            />
            <Route
              exact
              path="/vendors/:id_vendor/tours/:id_tour/tour-options/:id_tour_option/dates"
              component={TourOptionDatesEditContainer}
            />
            <Route exact path="/vendors/:id_vendor/tours/:id_tour/edit-tour-legs" component={TourLegsEditContainer} />
            <Route
              exact
              path="/vendors/:id_vendor/tours/:id_tour/edit-tour-reviews"
              component={TourReviewsEditContainer}
            />
            <Route exact path="/vendors/:id_vendor/addons/:id_addon" component={VendorAddonDetails} />
            <Route
              exact
              path="/vendors/:id_vendor/addons/:id_addon/deal-options/:id_addon_deal_option/edit"
              component={VendorAddonEdit}
            />

            <Route exact path="/bookings/:id_orders" component={BookingPage} />

            <Route path="/marketing/:tab?" component={MarketingPage} exact />
            <Route path="/accommodation/properties" component={AccommodationHomePage} exact />
            <Route path="/accommodation/properties/:propertyId" component={AccommodationPropertyDetailsPage} />
            <Route path="/email/render" component={RenderEmail} exact />
            <Route path="/email/sent" component={EmailPageContainer} exact />

            <Route exact path="/comped/:id_comped" component={CompedPackagesDetailContainer} />
            <Route exact path="/promos/code/:id_code" component={PromoDetailContainerV3} />
            <Route exact path="/promos/:id_promo" component={PromoDetailContainerV3} />
            <Route exact path="/badges" component={BadgesContainer} />
            <Route exact path="/badges/:id_badge" component={BadgesDetailContainer} />

            <Route exact path="/profile" component={ProfilePage} />
            <Route exact path="/finance" component={FinancePageContainer} />
            <Route exact path="/finance/offers/:id_offer" component={FinanceOfferDetailContainer} />
            <Route
              exact
              path="/finance/offers/:id_offer/offer-payments/:id_vendor/:id_offer_payment"
              component={VendorOfferPaymentsContainer}
            />
            <Route
              exact
              path="/finance/offers/:id_offer/offer-payments/:id_vendor"
              component={VendorOfferPaymentsContainer}
            />
            <Route
              exact
              path="/finance/offers/:id_offer/booking-payments/:id_vendor"
              component={VendorBookingPaymentsContainer}
            />
            <Route path="/finance/:section/:report" component={FinancePageContainer} />
            <Route path="/finance/:section" component={FinancePageContainer} />

            <Route exact path="/email" component={EmailPage} />
            <Route path="/email/:tab?" component={EmailPage} exact />

            <Route
              exact
              path="/customer-communication/hero-planner/schedules/:scheduleEditorId"
              component={HeroPlannerScheduleEditorPage}
            />
            <Route
              exact
              path="/customer-communication/hero-planner/schedules"
              component={HeroPlannerSchedulesListPage}
            />

            <Route exact path="/health" component={HealthPage} />

            <Route exact path="/cruises/:section" component={CruisesPage} />
            <Route exact path="/cruises/offer/:id_offer" component={CruisePageDetails} />
            <Route exact path="/cruises/offer/:offerId/images" component={CruisePageImages} />
            <Route exact path="/lere" component={LereAdminPageContainer} />
            <Route exact path="/lere/:section" component={LereAdminPageContainer} />
            <Route exact path="/cruises/ships/:shipId" component={ShipImageEditor} />
            <Route exact path="/cruises/cruise-lines/order" component={CruiseLineOrdering} />
            <Route exact path="/cruises/cruise-lines/:cruiseLineId" component={CruiseLineEdit} />
            <Route exact path="/cruises/ports/:portId" component={CruisePortsEditPage} />
            <Route exact path="/cruises/promo-banner/create" component={CruisePromoBannerCreate} />
            <Route exact path="/cruises/promo-banner/order" component={CruisePromoBannerOrdering} />
            <Route exact path="/cruises/promo-banner/:promoBannerId" component={CruisePromoBannerEdit} />
            <Route exact path="/cruises/promotion-management" component={PromotionManagementPage} />
            <Route exact path="/cruises/promotion-management/create" component={CreatePromotionPage} />
            <Route exact path="/cruises/promotion-management/:promotionId/edit" component={EditPromotionPage} />
            <Route exact path="/cruises/rate-code-management" component={RateCodeManagementPage} />
            <Route exact path="/cruises/rate-code-management/:rateCodeId/edit" component={EditRateCodePage} />
            <Route exact path="/cruises/sailings/:id_sailing" component={CruiseSailingDetailsPage} />
            <Route exact path="/cruises/cabin-categories/:id_cabin" component={CruiseCabinCategoriesDetailsPage} />
            <Route exact path="/cruises/cabin-categories-groups/create" component={CreateCabinCategoriesGroupPage} />
            <Route exact path="/cruises/inclusions/create" component={CruiseInclusionsCreate} />
            <Route exact path="/cruises/inclusions/edit/:inclusionId" component={CruiseInclusionsEdit} />
            <Route
              exact
              path="/cruises/inclusions/edit/:inclusionId/:tab(items|filters)/:action(create|edit)?/:actionId?"
              component={CruiseInclusionsEdit}
            />
            <Route
              exact
              path="/cruises/cabin-categories-groups/:id_group"
              component={CabinCategoriesGroupOverviewPage}
            />
            <Route exact path="/cruises/bookings/:booking_id/logs" component={CruisesBookingLogsPage} />
            <Route exact path="/cruises/booking-logs" component={CruisesBookingsPage} />

            <Route exact path="/property-metrics" component={MetricsResultsContainer} />

            <Route exact path="/lebusiness" component={BusinessMainPage} />
            <Route exact path="/lebusiness/:businessId/:tab" component={BusinessTabContainer} />

            <Route exact path="/support-assistant/:tab?" component={SupportAssistantPage} />

            <Route exact path="/amenities" component={AmenitiesPageContainer} />
            <Route exact path="/amenities/:id_amenity" component={AmenityEdit} />
            <Route exact path="/amenity-groups/:id_group" component={AmenityGroupEdit} />

            <Redirect from="*" to="/" />
          </Switch>
        </Authenticated>

        <NotAuthenticated>
          <Route exact path="*" component={LoginPage} />
        </NotAuthenticated>
      </Box>

      <Footer />
    </Box>
  );
}
