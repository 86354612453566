import { CruisesContract, definitions } from '@luxuryescapes/contract-svc-cruise';

import { SearchFilters } from '~/components/Cruises/pages/Home/components/Sailings/constants';

import cruisesAPI from './CruisesApiService';

type GetRateCodeById = definitions['GET_RATE_CODE_BY_ID'];
type PatchRateCodeById = definitions['PATCH_RATE_CODE_BY_ID'];

export type GetRateCodeByIdResponse = CruisesContract.Response<GetRateCodeById>;
export type PatchRateCodeByIdResponse = CruisesContract.Response<PatchRateCodeById>;

const RESOURCE = 'rate-codes';

const getRateCodeById = (id: string): Promise<GetRateCodeByIdResponse> => {
  return cruisesAPI.http.get({
    resource: `${RESOURCE}/${id}`,
  });
};

const getRateCodeList = async (
  params?: CruisesContract.RateCodeListQuery,
): Promise<CruisesContract.Response<CruisesContract.RateCodeListResponse[]>> => {
  const queryParams = {
    ...(params?.skip && { skip: params.skip }),
    ...(params?.take && { take: params.take }),
    ...(params?.brand && { brand: params.brand }),
    ...(params?.rateCodes && { rateCodes: params.rateCodes }),
    ...(params?.vendorCode && { vendorCode: params.vendorCode }),
    ...(params?.promotionName && { promotionName: params.promotionName }),
  };

  return await cruisesAPI.httpV1.get({
    resource: `${RESOURCE}`,
    paginationParams: queryParams,
  });
};

const createRateCodeByDepartureId = async (
  params: CruisesContract.CreateRateCodeByDepartureBody,
): Promise<CruisesContract.Response<CruisesContract.GenericMessageResponse>> => {
  const { rateCode, vendorId, departureIds, isFlash } = params;

  return await cruisesAPI.httpV1.post({
    resource: `${RESOURCE}/departures`,
    body: { rateCode, vendorId, departureIds, isFlash },
  });
};

const createBulkRateCodeByDepartureId = async (
  params: SearchFilters & {
    rateCodes: string[];
    departureIds: string[];
    isFlash: boolean;
    isSelectAll: boolean;
  },
): Promise<CruisesContract.Response<CruisesContract.GenericMessageResponse>> => {
  const {
    rateCodes,
    departureIds,
    isFlash,
    isSelectAll,
    name,
    offerId,
    vendorId,
    sailingExternalId,
    sailingPlan,
    sailingDurationMin,
    sailingDurationMax,
    departureDateStart,
    departureDateEnd,
    departurePortExternalIds,
    status: departureStatus,
    hasCoreChanges,
    hasItineraryChanges,
    shipName,
    shipExternalId,
  } = params;

  return await cruisesAPI.http.post({
    resource: `${RESOURCE}`,
    body: {
      rateCodes,
      departureIds,
      isFlash,
      isSelectAll,
      name,
      offerId,
      vendorId,
      sailingExternalId,
      sailingPlan,
      sailingDurationMin,
      sailingDurationMax,
      departureDateStart,
      departureDateEnd,
      departurePortExternalIds,
      departureStatus,
      hasCoreChanges: hasCoreChanges === 'ALL' ? undefined : hasCoreChanges === 'TRUE',
      hasItineraryChanges: hasItineraryChanges === 'ALL' ? undefined : hasItineraryChanges === 'TRUE',
      shipName,
      shipExternalId,
    },
  });
};

const patchRateCodeById = (
  id: string,
  params: { isFlash: boolean },
): Promise<CruisesContract.Response<PatchRateCodeByIdResponse>> => {
  const { isFlash } = params;

  return cruisesAPI.http.patch({
    resource: `${RESOURCE}/${id}`,
    body: { isFlash },
  });
};

export default {
  getRateCodeById,
  getRateCodeList,
  createRateCodeByDepartureId,
  patchRateCodeById,
  createBulkRateCodeByDepartureId,
};
